import React from 'react'
import { Box } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET
}

const Image = ({ value, aspectRatio, w, maxW, maxH, widthArg = null }) => {
  if (!value?.asset) {
    return null
  }
  return (
    <Box
      as={GatsbyImage}
      image={getGatsbyImageData(
        value,
        {
          // https://www.sanity.io/docs/image-urls#auto-777d41f23d56
          auto: 'format',
          // width: widthArg,
          hotspot: value.hotspot,
          aspectRatio,
          layout: 'constrained',
          placeholder: 'dominantColor'
        },
        sanityConfig
      )}
      alt={value.asset?.altText || ''}
      w={w}
      maxW={maxW}
      maxH={maxH}
    />
  )
}

export default Image
