import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const sm = defineStyle({
  fontSize: 'sm',
  h: null,
  px: 'lg',
  py: 'xs'
})

const md = defineStyle({
  fontSize: 'sm',
  h: null,
  px: 'xl',
  py: 'lg'
})

const lg = defineStyle({
  fontSize: 'md',
  h: null,
  px: 'xl',
  py: 'xl'
})

const filled = defineStyle({
  bg: 'white',
  fontWeight: 'bold',
  borderRadius: 'xs',
  _hover: {
    bg: 'white',
    borderColor: 'brand.500'
  },
  _focus: {
    bg: 'white',
    borderColor: 'brand.500'
  }
})
// eslint-disable-next-line import/no-anonymous-default-export
export default defineStyleConfig({
  baseStyle: {
    minH: '9em'
  },
  variants: {
    filled
  },
  sizes: {
    sm,
    md,
    lg
  },
  defaultProps: {
    variant: 'filled'
  }
})
