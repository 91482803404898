// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sm: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
  lg: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
  button:
    '0px 16px 65px rgba(168, 181, 205, 0.1), 90px 10px 38px rgba(168, 181, 205, 0.08), 0px 6px 20px rgba(168, 181, 205, 0.06), 0px 3px 10px rgba(168, 181, 205, 0.06), 0px 1px 5px rgba(168, 181, 205, 0.05), 0px 3px 2.5px rgba(168, 181, 205, 0.05)',
  box: '0px 2px 20px rgba(33, 33, 36, 0.1)',
  panel:
    '0px 0px 6px -45px rgba(33, 35, 36, 0.16), 0px 0px 30px -45px rgba(33, 35, 36, 0.22), 0px 0px 80px -45px rgba(33, 35, 36, 0.35)'
}
