// eslint-disable-next-line import/no-anonymous-default-export
export default {
  none: '0',
  // xs: '0.3125rem',
  xs: '0.625rem',
  sm: '1.563rem', // 25px
  base: '2.5rem', // 40px
  lg: '3.75rem', // 60px
  full: '9999px'
}
