import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const highlight = defineStyle({
  textStyle: 'highlight'
})
// eslint-disable-next-line import/no-anonymous-default-export
export default defineStyleConfig({
  baseStyle: {},
  variants: {
    highlight
  }
})
