import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const text = defineStyle({
  fontSize: null,
  lineHeight: null,
  textStyle: 'h5-xs'
})
const sm = defineStyle({
  fontSize: null,
  lineHeight: null,
  textStyle: 'h3'
})
const md = defineStyle({
  fontSize: null,
  lineHeight: null,
  textStyle: 'h4'
})
const lg = defineStyle({
  fontSize: null,
  lineHeight: null,
  textStyle: 'h2'
})
const xl = defineStyle({
  fontSize: null,
  lineHeight: null,
  textStyle: 'h1'
})
// eslint-disable-next-line import/no-anonymous-default-export
export default defineStyleConfig({
  baseStyle: {
    textStyle: 'h2',
    fontWeight: null // the inherited fontWeight defaultStyle was overriding the weight set by textStyle
  },
  sizes: {
    text,
    sm,
    md,
    lg,
    xl
  },
  defaultProps: {
    size: 'md'
  }
})
