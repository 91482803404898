import { graphql, useStaticQuery } from 'gatsby'
import { useContext } from 'react'
import { PageContext } from '../context'

const useSeo = () => {
  const pageContext = useContext(PageContext)
  const { sanityDocuments } = useStaticQuery(
    graphql`
      query {
        sanityDocuments: allSanitySeo {
          nodes {
            i18n_lang
            separator
            opengraphThumbnail {
              asset {
                url
              }
            }
            twitterCardType
            logo {
              asset {
                url
              }
            }
            title
            contactPoints {
              contactType
              email
              areaServed
              availableLanguage
            }
            addressPoints {
              street
              city
              region
              postalCode
              country
            }
            profiles
            foundingDate
            legalName
            taxID
            vatID
            homepage {
              metaTitle
              metaDescription
              thumbnail {
                asset {
                  url
                }
              }
            }
            notFound {
              metaTitle
              metaDescription
              thumbnail {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  const sanityDocumentsLocalized = sanityDocuments.nodes.filter(
    node => node.i18n_lang === (pageContext.i18n_lang || 'en_GB')
  )
  if (!sanityDocumentsLocalized?.length) {
    console.warn('No document found!', pageContext.i18n_lang)
  }
  return sanityDocumentsLocalized.shift()
}

export default useSeo
