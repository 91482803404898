import {
  Button,
  SimpleGrid,
  Flex,
  Text,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react'
import React from 'react'

const RadioButton = props => {
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props)
  return (
    <Button
      as={'label'}
      flex={{ base: '1 1 50%', xl: '1 1 0' }}
      size={'lg'}
      variant={'tab'}
      bg={'white'}
      cursor={'pointer'}
      colorScheme={'blank'}
      {...getCheckboxProps()}
      _checked={{
        bg: 'brand.600',
        borderColor: 'brand.600',
        color: 'white'
      }}
    >
      <input {...getInputProps()} hidden />
      {props.children}
    </Button>
  )
}

const RadioButtons = ({
  name,
  options = [],
  label,
  disabled,
  onChange = () => {},
  ...props
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange
  })
  return (
    <React.Fragment>
      {label && (
        <Text colorScheme={'gray'} fontWeight={'bold'} pb={'lg'}>
          {label}
        </Text>
      )}
      <SimpleGrid
        columns={{ base: 2, md: 3, lg: 5 }}
        spacing={'lg'}
        {...getRootProps()}
        {...props}
      >
        {options &&
          options.map(({ label, value }) => (
            <RadioButton
              key={value}
              value={value}
              {...getRadioProps({ value })}
              isDisabled={disabled}
            >
              {label}
            </RadioButton>
          ))}
      </SimpleGrid>
    </React.Fragment>
  )
}

export default RadioButtons
