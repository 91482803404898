import {
  Box,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  useBreakpointValue
} from '@chakra-ui/react'
import React from 'react'
import {useHomepage} from '../../gatsby-source-sanity'
import {ShowcaseCard} from '../projects'
import {FadeUp} from '../UI/atoms'

const Showcase = () => {
  const {showcase_title, showcase_projects} = useHomepage()
  const disableRatio = useBreakpointValue({xl: false, base: true})
  const disabledRatio = useBreakpointValue({
    sm: 1.2,
    md: 1.5,
    lg: 1.8,
    base: 1
  })
  return <Box
    bg={'gray.200'}
    pb={'120'}
  >
    <Container variant={'gutters-lg'}>
      <Heading size={'lg'} textAlign={'center'} mb={'xl'} pt={190}>
        {showcase_title}
      </Heading>
      <SimpleGrid columns={{base: 1, xl: 2}} spacing={'xl'}>
        {showcase_projects &&
          showcase_projects.map((project, index) => (
            <GridItem
              colSpan={{base: 1, xl: index % 3 === 0 ? 2 : 1}}
              alignItems={'center'}
              key={'showcase-' + project._id}
            >
              <FadeUp multiplier={index}>
                <ShowcaseCard
                  size={
                    disableRatio ? disabledRatio : index % 3 === 0 ? 'lg' : 'md'
                  }
                  showFooter={false}
                  index={index++}
                  {...project}
                />
              </FadeUp>
            </GridItem>
          ))}
      </SimpleGrid>
    </Container>
  </Box>
}

export default Showcase
