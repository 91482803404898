// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 'wider'
  },
  highlight: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderBottom: '0.15em solid',
    borderColor: 'brand.500',
    color: 'brand.500'
  },
  'play-down': {
    color: '#73737B',
    fontWeight: 'medium',
    letterSpacing: '0.0075em',
    lineHeight: 'short'
  },
  'big-text': {
    fontSize: 'lg',
    fontWeight: 'medium',
    letterSpacing: 'wide',
    lineHeight: 'short'
  },
  h1: {
    // 3xl-700-tighter-shorter
    fontSize: {
      base: 'xl',
      md: '2xl',
      lg: '3xl',
      xl: '3xl'
    },
    fontWeight: 'bold',
    letterSpacing: 'tighter',
    lineHeight: 'shorter'
  },
  h2: {
    // 2xl-700-tight-short
    fontSize: '2xl',
    fontWeight: 'bold',
    letterSpacing: '-0.025em', // .025 in design
    lineHeight: 'shorter'
  },
  h3: {
    // xl-700-tight-short
    fontSize: 'xl',
    fontWeight: 'bold',
    letterSpacing: 'tight',
    lineHeight: 'shorter'
  },
  h4: {
    // lg-500-wide-short
    fontSize: 'lg',
    fontWeight: 'medium',
    letterSpacing: 'wide',
    lineHeight: 'short'
  },
  h5: {
    // md-700-tight-base
    fontSize: 'md',
    fontWeight: 'bold',
    letterSpacing: 'tight',
    lineHeight: 'base'
  },
  'h5-xs': {
    // h5_xs-700-tight-base;
    fontSize: 'sm',
    fontWeight: 'bold',
    lineHeight: '1.48',
    letterSpacing: 'tight',
    textAlign: 'left'
  },
  'h4-numbers': {
    // lg-500-wide-base-numbers
    fontSize: 'lg',
    fontWeight: 'medium',
    letterSpacing: 'wide',
    lineHeight: 'base',
    fontFeatureSettings: `'tnum' on, 'lnum' on`
  },
  'text-large': {
    // md-500-wide-short
    fontSize: 'md',
    fontWeight: 'medium',
    letterSpacing: 'wide',
    lineHeight: 'short'
  },
  text: {
    // sm-400-normal-base
    fontSize: 'sm',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'base'
  },
  'text-cta': {
    // sm-400-normal-base
    fontSize: 'sm',
    fontWeight: 'medium',
    letterSpacing: 'tight',
    lineHeight: 'base'
  },
  'text-short': {
    // sm-400-wide-short
    fontSize: 'sm',
    fontWeight: 'normal',
    letterSpacing: 'wide',
    lineHeight: 'short'
  },
  'text-bold': {
    // sm-700-normal-base
    fontSize: 'sm',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'base'
  },
  button: {
    // sm-700-wider-none
    fontSize: 'sm',
    fontWeight: 'bold',
    letterSpacing: 'wider',
    lineHeight: 'none',
    textTransform: 'uppercase'
  },
  'button-small': {
    // sm-700-wider-none
    fontSize: 'sm',
    fontWeight: 'bold',
    letterSpacing: 'wider',
    lineHeight: 'none',
    textTransform: 'uppercase'
  }
}
