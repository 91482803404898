import { PortableText } from '@portabletext/react'
import urlBuilder from '@sanity/image-url'
import { graphql } from 'gatsby'
import React from 'react'

const SampleImageComponent = ({ value, isInline }) => {
  return (
    <img
      src={urlBuilder()
        .image(value)
        .width(isInline ? 100 : 800)
        .fit('max')
        .auto('format')
        .url()}
      alt={value.alt || ' '}
      loading="lazy"
      style={{
        // Display alongside text if image appears inside a block text span
        display: isInline ? 'inline-block' : 'block'

        // Avoid jumping around with aspect-ratio CSS property
        // aspectRatio: width / height,
      }}
    />
  )
}

const serializers = {
  types: {
    image: SampleImageComponent,
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    span: ({ node }) => <span {...node} />
  },
  marks: {
    // // Ex. 1: custom renderer for the em / italics decorator
    // em: ({children}) => <em className="text-gray-600 font-semibold">{children}</em>,
    //
    // // Ex. 2: rendering a custom `link` annotation
    link: ({ value, children }) => {
      const target = (value?.href || '').startsWith('http')
        ? '_blank'
        : undefined
      return (
        <a
          href={value?.href}
          target={target}
          rel={target === '_blank' && 'noindex nofollow'}
        >
          {children}
        </a>
      )
    }
  }
  // list: {
  // 	// Ex. 1: customizing common list types
  // 	bullet: ({children}) => <ul className="mt-xl">{children}</ul>,
  // 	number: ({children}) => <ol className="mt-lg">{children}</ol>,
  //
  // 	// Ex. 2: rendering custom lists
  // 	checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
  // },
  // listItem: {
  // 	// Ex. 1: customizing common list types
  // 	bullet: ({children}) => <li style={{listStyleType: 'disclosure-closed'}}>{children}</li>,
  //
  // 	// Ex. 2: rendering custom list items
  // 	checkmarks: ({children}) => <li>✅ {children}</li>,
  // },
}

const ModuleSerializer = ({ blocks }) => (
  <PortableText
    value={blocks}
    components={serializers}
    onMissingComponent={(message, options) => {
      console.warn(message, {
        // eg `someUnknownType`
        type: options.type,

        // 'block' | 'mark' | 'blockStyle' | 'listStyle' | 'listItemStyle'
        nodeType: options.nodeType
      })
    }}
  />
)

export default ModuleSerializer

export const fragment = graphql`
  fragment ModuleSerializer on SanityBrandsOrButtonOrColumnsOrCtaBoxOrDottedImageOrImageOrMembersOrSectionTitleOrStatCounter {
    ... on SanityMembers {
      _key
      _type
    }
    ... on SanityBrands {
      _key
      _type
    }
    ... on SanitySectionTitle {
      _key
      _type
    }
  }
`
