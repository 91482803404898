import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const sm = definePartsStyle({
  field: {
    fontSize: 'sm',
    h: null,
    px: 'lg',
    py: 'xs'
  }
})

const md = definePartsStyle({
  field: {
    fontSize: 'sm',
    h: null,
    px: 'xl',
    py: 'sm'
  }
})

const lg = definePartsStyle({
  field: {
    fontSize: 'sm',
    h: null,
    px: 'xl',
    py: '1.125rem'
  }
})

const filled = defineStyle(props => {
  const { colorScheme: c, bg } = props
  return {
    field: {
      bg: bg === 'white' ? 'white' : 'gray.200',
      borderColor: bg === 'white' ? 'white' : 'gray.200',
      fontWeight: 'bold',
      borderRadius: 'xs',
      _hover: {
        borderColor: 'brand.500',
        // bg: bg === 'white' ? 'white' : 'gray.200',
        bg: 'white'
      },
      _focus: {
        borderColor: 'brand.500',
        // bg: bg === 'white' ? 'white' : 'gray.200',
        bg: 'white'
      }
    }
  }
})
// eslint-disable-next-line import/no-anonymous-default-export
export default defineMultiStyleConfig({
  baseStyle: {
    field: {
      _placeholder: {
        color: 'gray.400'
      }
    }
  },
  variants: {
    filled
  },
  sizes: {
    sm,
    md,
    lg
  },
  defaultProps: {
    variant: 'filled'
  }
})
