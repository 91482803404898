import { extendTheme } from '@chakra-ui/react'
import foundations from './foundations'
import components from './components'
import styles from './styles'
// eslint-disable-next-line import/no-anonymous-default-export
const theme = extendTheme(
  {
    styles,
    images: {
      gallery_thumbnail: {
        width: 250,
        height: 250,
        borderRadius: 'lg'
      }
    },
    ...foundations,
    components
  }
  // withDefaultColorScheme({
  // 	colorScheme: 'brand',
  // 	components: ['Button']
  // })
)

export default theme
