import React from 'react'
import { Box } from '@chakra-ui/react'

const ApisSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 1005 580"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M356.57 35.2195H528.77C532.99 35.2195 536.4 38.6395 536.4 42.8495V251.9C536.4 256.12 532.98 259.53 528.77 259.53H176.57C172.35 259.53 168.94 256.11 168.94 251.9V42.8495H168.91V15.3395C168.91 8.94953 174.09 3.76953 180.48 3.76953H321.1C325.49 3.76953 329.38 7.12953 331.46 10.1695L344.92 30.1995C347.64 34.3695 352.18 35.2195 356.56 35.2195H356.57Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M176.61 259.75H528.8C533.02 259.75 536.431 256.33 536.431 252.12L566.21 72.63C566.21 68.41 562.791 65 558.581 65H206.391C202.171 65 198.76 68.42 198.76 72.63L168.98 252.12C168.98 256.34 172.4 259.75 176.61 259.75Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M171.758 112.719C172.328 117.609 175.548 120.889 179.758 121.149C184.168 121.419 188.228 118.639 189.028 113.539L171.998 234.519L171.768 112.729L171.758 112.719Z"
      fill="#383838"
    />
    <path
      d="M377.62 117.169C374.55 122.239 373.69 130.099 376.72 134.929C377.41 136.029 379.68 140.219 384.78 142.179C390.02 144.189 394.98 143.089 402.15 142.179C405.72 141.729 412.87 141.639 418.74 143.819C423.94 145.749 428.68 140.939 427.36 135.549C423.98 121.819 414.66 110.529 403.87 107.839C393.71 105.299 385.97 109.559 385.97 109.559C384.47 110.339 380.46 112.489 377.63 117.159L377.62 117.169Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M503.549 117.169C506.619 122.239 507.48 130.099 504.45 134.929C503.76 136.029 501.489 140.219 496.389 142.179C491.149 144.189 486.189 143.089 479.019 142.179C475.449 141.729 468.3 141.639 462.43 143.819C457.23 145.749 452.489 140.939 453.809 135.549C457.189 121.819 466.509 110.529 477.299 107.839C487.459 105.299 495.2 109.559 495.2 109.559C496.7 110.339 500.709 112.489 503.539 117.159L503.549 117.169Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M441.288 168.198H439.719L419.758 168.168L415.338 168.238C413.928 168.238 412.598 168.938 411.798 170.098C409.498 173.478 407.188 176.858 404.888 180.228C404.418 180.918 403.388 180.898 402.958 180.188L396.868 170.338C396.458 169.888 395.309 168.498 393.469 168.498C392.709 168.498 392.338 168.468 391.688 168.468L387.268 168.488H378.608C377.108 168.498 375.848 169.718 375.778 171.218C375.618 174.568 375.868 178.808 377.008 183.338C382.908 206.708 403.698 224.208 440.778 224.208"
      fill="#7A6CFF"
    />
    <path
      d="M441.288 168.198H439.719L419.758 168.168L415.338 168.238C413.928 168.238 412.598 168.938 411.798 170.098C409.498 173.478 407.188 176.858 404.888 180.228C404.418 180.918 403.388 180.898 402.958 180.188L396.868 170.338C396.458 169.888 395.309 168.498 393.469 168.498C392.709 168.498 392.338 168.468 391.688 168.468L387.268 168.488H378.608C377.108 168.498 375.848 169.718 375.778 171.218C375.618 174.568 375.868 178.808 377.008 183.338C382.908 206.708 403.698 224.208 440.778 224.208"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M439.699 168.198H441.269L461.229 168.168L465.649 168.238C467.059 168.238 468.389 168.938 469.189 170.098C471.489 173.478 473.799 176.858 476.099 180.228C476.569 180.918 477.599 180.898 478.029 180.188L484.119 170.338C484.529 169.888 485.679 168.498 487.519 168.498C488.279 168.498 488.649 168.468 489.299 168.468L493.719 168.488H502.379C503.879 168.498 505.139 169.718 505.209 171.218C505.369 174.568 505.119 178.808 503.979 183.338C498.079 206.708 477.289 224.208 440.209 224.208"
      fill="#7A6CFF"
    />
    <path
      d="M439.699 168.198H441.269L461.229 168.168L465.649 168.238C467.059 168.238 468.389 168.938 469.189 170.098C471.489 173.478 473.799 176.858 476.099 180.228C476.569 180.918 477.599 180.898 478.029 180.188L484.119 170.338C484.529 169.888 485.679 168.498 487.519 168.498C488.279 168.498 488.649 168.468 489.299 168.468L493.719 168.488H502.379C503.879 168.498 505.139 169.718 505.209 171.218C505.369 174.568 505.119 178.808 503.979 183.338C498.079 206.708 477.289 224.208 440.209 224.208"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M187.671 226.729H185.881C183.641 209.499 168.921 196.199 151.091 196.199C133.261 196.199 118.542 209.509 116.302 226.729H103.141C87.9814 226.729 75.6914 239.019 75.6914 254.179C75.6914 269.339 87.9814 281.629 103.141 281.629H187.671C202.831 281.629 215.122 269.339 215.122 254.179C215.122 239.019 202.831 226.729 187.671 226.729Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M934.742 291.16H898.691C889.571 291.16 881.991 298.46 880.821 307.83H875.961C866.051 307.83 857.941 316.45 857.941 327C857.941 337.54 866.051 346.17 875.961 346.17H912.011C921.131 346.17 928.711 338.87 929.881 329.5H934.742C944.652 329.5 952.761 320.88 952.761 310.33C952.761 299.79 944.652 291.16 934.742 291.16Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M909.391 122.23C908.311 98.3503 888.611 79.3203 864.471 79.3203C847.131 79.3203 832.101 89.1403 824.591 103.51C819.781 100.55 814.121 98.8403 808.061 98.8403C793.721 98.8403 781.631 108.41 777.781 121.5H774.541C755.121 121.5 739.371 137.25 739.371 156.67C739.371 176.09 755.121 191.84 774.541 191.84H902.191C921.611 191.84 937.361 176.09 937.361 156.67C937.361 139.71 925.361 125.56 909.381 122.24L909.391 122.23Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M0.0898438 576.66H1004.5"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M223.182 531.789H67.6313C64.3011 531.789 61.6016 534.489 61.6016 537.819V570.009C61.6016 573.339 64.3011 576.039 67.6313 576.039H223.182C226.512 576.039 229.211 573.339 229.211 570.009V537.819C229.211 534.489 226.512 531.789 223.182 531.789Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M223.182 487.5H67.6313C64.3011 487.5 61.6016 490.2 61.6016 493.53V525.72C61.6016 529.05 64.3011 531.75 67.6313 531.75H223.182C226.512 531.75 229.211 529.05 229.211 525.72V493.53C229.211 490.2 226.512 487.5 223.182 487.5Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M223.182 443.469H67.6313C64.3011 443.469 61.6016 446.168 61.6016 449.499V481.689C61.6016 485.019 64.3011 487.719 67.6313 487.719H223.182C226.512 487.719 229.211 485.019 229.211 481.689V449.499C229.211 446.168 226.512 443.469 223.182 443.469Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M223.182 399.18H67.6313C64.3011 399.18 61.6016 401.879 61.6016 405.21V437.4C61.6016 440.73 64.3011 443.43 67.6313 443.43H223.182C226.512 443.43 229.211 440.73 229.211 437.4V405.21C229.211 401.879 226.512 399.18 223.182 399.18Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M223.182 355.77H67.6313C64.3011 355.77 61.6016 358.469 61.6016 361.8V393.99C61.6016 397.32 64.3011 400.02 67.6313 400.02H223.182C226.512 400.02 229.211 397.32 229.211 393.99V361.8C229.211 358.469 226.512 355.77 223.182 355.77Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M233.111 448.121H858.241C860.621 448.121 862.551 450.051 862.551 452.431V482.581C862.551 484.961 860.621 486.891 858.241 486.891H233.111C230.731 486.891 228.801 484.961 228.801 482.581V452.431C228.801 450.051 230.731 448.121 233.111 448.121Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M588.07 325.262C597.55 328.762 607.54 333.362 617.65 339.432C668.71 370.092 691.311 419.452 700.561 444.742C703.981 439.672 742.161 385.012 810.351 384.242C864.501 383.632 899.56 417.412 906.21 424.062"
      stroke="#383838"
      strokeWidth="6"
      strokeLinejoin="round"
    />
    <path
      d="M575.789 355.35C596.991 355.35 614.179 338.163 614.179 316.96C614.179 295.758 596.991 278.57 575.789 278.57C554.586 278.57 537.398 295.758 537.398 316.96C537.398 338.163 554.586 355.35 575.789 355.35Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M362.621 448.499V315.129"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M730.75 448.5V318.66"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M436.242 448.497V368.688"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M509.871 448.5V390.02"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M583.5 448.5V390.02"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M657.121 448.497V368.688"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M548.36 420.21C472.57 420.21 401.31 390.74 347.71 337.24C294.11 283.73 264.59 212.59 264.59 136.91H289.53C289.53 279.37 405.64 395.27 548.36 395.27C691.08 395.27 807.19 279.37 807.19 136.91H832.13C832.13 212.58 802.61 283.73 749.01 337.24C695.41 390.74 624.16 420.21 548.36 420.21Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M264.578 445.139V139.799C264.578 137.539 266.378 135.719 268.608 135.719H287.388C289.608 135.719 291.418 137.549 291.418 139.799V445.139"
      fill="white"
    />
    <path
      d="M264.578 445.139V139.799C264.578 137.539 266.378 135.719 268.608 135.719H287.388C289.608 135.719 291.418 137.549 291.418 139.799V445.139"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M832.129 445.139V139.799C832.129 137.539 830.329 135.719 828.099 135.719H809.319C807.099 135.719 805.289 137.549 805.289 139.799V445.139"
      fill="white"
    />
    <path
      d="M832.129 445.139V139.799C832.129 137.539 830.329 135.719 828.099 135.719H809.319C807.099 135.719 805.289 137.549 805.289 139.799V445.139"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M614.191 211.269C622.514 211.269 629.261 204.522 629.261 196.199C629.261 187.876 622.514 181.129 614.191 181.129C605.868 181.129 599.121 187.876 599.121 196.199C599.121 204.522 605.868 211.269 614.191 211.269Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M685.778 141.28C691.097 141.28 695.409 136.968 695.409 131.65C695.409 126.331 691.097 122.02 685.778 122.02C680.46 122.02 676.148 126.331 676.148 131.65C676.148 136.968 680.46 141.28 685.778 141.28Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M695.41 275.179C707.599 275.179 717.48 265.298 717.48 253.109C717.48 240.92 707.599 231.039 695.41 231.039C683.221 231.039 673.34 240.92 673.34 253.109C673.34 265.298 683.221 275.179 695.41 275.179Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M310.568 531.789H155.018C151.688 531.789 148.988 534.489 148.988 537.819V570.009C148.988 573.339 151.688 576.039 155.018 576.039H310.568C313.898 576.039 316.598 573.339 316.598 570.009V537.819C316.598 534.489 313.898 531.789 310.568 531.789Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M310.568 487.5H155.018C151.688 487.5 148.988 490.2 148.988 493.53V525.72C148.988 529.05 151.688 531.75 155.018 531.75H310.568C313.898 531.75 316.598 529.05 316.598 525.72V493.53C316.598 490.2 313.898 487.5 310.568 487.5Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M938.4 531.789H782.851C779.52 531.789 776.82 534.489 776.82 537.819V570.009C776.82 573.339 779.52 576.039 782.851 576.039H938.4C941.731 576.039 944.431 573.339 944.431 570.009V537.819C944.431 534.489 941.731 531.789 938.4 531.789Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M938.4 487.5H782.851C779.52 487.5 776.82 490.2 776.82 493.53V525.72C776.82 529.05 779.52 531.75 782.851 531.75H938.4C941.731 531.75 944.431 529.05 944.431 525.72V493.53C944.431 490.2 941.731 487.5 938.4 487.5Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M780.169 536.96C780.169 490.92 861.129 490.28 861.129 490.28L778.68 489.43L780.18 536.97L780.169 536.96Z"
      fill="#383838"
    />
    <path
      d="M314.64 536.96C314.64 490.92 233.68 490.28 233.68 490.28L316.129 489.43L314.629 536.97L314.64 536.96Z"
      fill="#383838"
    />
    <path
      d="M300.068 268.199C300.068 268.199 289.128 246.719 306.168 235.779L291.418 187.879V261.039L300.068 268.199Z"
      fill="#383838"
    />
    <path
      d="M796.639 268.199C796.639 268.199 807.579 246.719 790.539 235.779L805.289 187.879V261.039L796.639 268.199Z"
      fill="#383838"
    />
  </Box>
)

export default ApisSVG
