import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const brand = defineStyle({
  bg: `brand.500`,
  color: 'white'
})

const subtle = defineStyle({
  bg: `gray.100`
})

const lg = defineStyle({
  py: 'sm'
})
// eslint-disable-next-line import/no-anonymous-default-export
export default defineStyleConfig({
  baseStyle: {
    textTransform: null,
    // fontSize: null,
    px: 'xl',
    borderRadius: 'base',
    py: 'xxs'
  },
  sizes: {
    lg
  },
  variants: {
    brand,
    subtle
  }
})
