import React from 'react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Image } from '@chakra-ui/react'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET
}

const SanityImage = ({ image, alt, width, height, aspectRatio, ...props }) => {
  if (!image) {
    return null
  }
  return (
    <Image
      as={GatsbyImage}
      w={width}
      h={height}
      image={getGatsbyImageData(
        image,
        {
          // https://www.sanity.io/docs/image-urls#auto-777d41f23d56
          auto: 'format',
          width,
          height,
          aspectRatio,
          hotspot: image?.hotspot,
          layout: 'constrained',
          fit: 'clip',
          placeholder: 'dominantColor'
        },
        sanityConfig
      )}
      alt={alt || image?.asset?.altText || ''}
      {...props}
    />
  )
}

export default SanityImage
