import React from 'react'
import { Box } from '@chakra-ui/react'

const Logo = props => {
  return (
    <Box
      as={'svg'}
      {...props}
      viewBox="0 0 618 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1005 88.6305C10.2256 87.026 6.83213 84.4454 4.25047 81.1402C1.88956 78.0567 0.491152 74.3451 0.230469 70.4703H18.7305C18.8295 71.4929 19.1489 72.4819 19.6668 73.3692C20.1847 74.2566 20.8887 75.0213 21.7305 75.6105C23.6033 76.9733 25.8752 77.6767 28.1905 77.6105C30.0951 77.7231 31.9881 77.2445 33.6105 76.2403C34.2024 75.8553 34.6878 75.3274 35.0216 74.7052C35.3555 74.0829 35.527 73.3864 35.5205 72.6803C35.5245 71.8288 35.2626 70.9974 34.7714 70.3019C34.2803 69.6063 33.5842 69.0814 32.7805 68.8004C29.9116 67.6207 26.9476 66.6873 23.9205 66.0104C20.2037 65.1872 16.5483 64.108 12.9805 62.7804C10.0579 61.6481 7.46322 59.8062 5.43047 57.4205C3.31047 54.9805 2.25047 51.6706 2.25047 47.5206C2.2232 44.1071 3.23461 40.7659 5.15047 37.9405C7.28971 34.8957 10.2519 32.5228 13.6905 31.1002C17.9298 29.3259 22.4962 28.4672 27.0905 28.5802C34.7505 28.5802 40.7905 30.4773 45.2105 34.2706C49.6393 38.1064 52.3713 43.538 52.8105 49.3805H35.4405C35.3287 48.4041 35.0246 47.4594 34.5457 46.6012C34.0669 45.743 33.4227 44.9883 32.6505 44.3805C30.8639 43.0718 28.6826 42.4154 26.4705 42.5206C24.7139 42.4167 22.968 42.8565 21.4705 43.7804C20.9108 44.1605 20.4567 44.6765 20.1507 45.2799C19.8446 45.8833 19.6966 46.5544 19.7205 47.2306C19.7253 48.0942 19.9955 48.9354 20.4946 49.6402C20.9937 50.345 21.6975 50.8792 22.5105 51.1705C25.3141 52.3367 28.2291 53.2147 31.2105 53.7906C34.9479 54.6967 38.6278 55.8252 42.2305 57.1705C45.1407 58.3471 47.714 60.2262 49.7205 62.6402C51.8671 65.1602 52.9805 68.5337 53.0605 72.7604C53.1141 76.1928 52.0623 79.5516 50.0605 82.3404C47.8451 85.3049 44.8507 87.5967 41.4105 88.9606C37.2616 90.6395 32.8153 91.4591 28.3405 91.3702C23.4546 91.461 18.6038 90.5279 14.1005 88.6305V88.6305Z"
        fill="#202024"
      />
      <path
        d="M89.3498 31.1808C92.824 29.3662 96.7013 28.4615 100.62 28.551C105.452 28.4944 110.199 29.8236 114.3 32.381C118.492 35.0505 121.85 38.8445 123.99 43.3307C126.33 48.5344 127.54 54.1754 127.54 59.881C127.54 65.5867 126.33 71.2272 123.99 76.4308C121.853 80.9452 118.496 84.7727 114.3 87.4811C110.213 90.0749 105.46 91.4259 100.62 91.3708C96.7217 91.4488 92.8666 90.5444 89.4098 88.7409C86.3407 87.1562 83.7227 84.8203 81.7998 81.9509V119.601H63.0898V29.4308H81.7998V38.071C83.688 35.1682 86.287 32.7963 89.3498 31.1808V31.1808ZM104.62 48.8508C103.389 47.5544 101.901 46.5293 100.252 45.8405C98.6022 45.1518 96.8271 44.8145 95.0398 44.8508C93.2669 44.8228 91.508 45.169 89.8779 45.8669C88.2479 46.5647 86.7833 47.5988 85.5798 48.9011C82.9932 51.5677 81.6965 55.2509 81.6898 59.9509C81.6832 64.6509 82.9798 68.3175 85.5798 70.9509C86.803 72.2411 88.2761 73.2686 89.9094 73.9709C91.5427 74.6731 93.302 75.0353 95.0798 75.0353C96.8577 75.0353 98.617 74.6731 100.25 73.9709C101.884 73.2686 103.357 72.2411 104.58 70.9509C107.213 68.2109 108.527 64.5077 108.52 59.841C108.513 55.1743 107.213 51.5108 104.62 48.8508Z"
        fill="#202024"
      />
      <path
        d="M156.101 29.4297V90.4897H137.391V29.4297H156.101Z"
        fill="#202024"
      />
      <path
        d="M196.592 31.6708C199.912 29.7298 203.697 28.7275 207.542 28.7709V48.5805H202.392C197.725 48.5805 194.225 49.5805 191.892 51.5805C189.559 53.5805 188.392 57.1005 188.392 62.1405V90.4808H169.672V29.4208H188.392V39.6005C190.47 36.3547 193.278 33.6396 196.592 31.6708V31.6708Z"
        fill="#202024"
      />
      <path
        d="M235.232 29.4297V90.4897H216.512V29.4297H235.232Z"
        fill="#202024"
      />
      <path
        d="M218.541 18.3108C217.604 17.3715 216.867 16.2513 216.376 15.0188C215.884 13.7863 215.648 12.4668 215.681 11.1404C215.636 9.78624 215.871 8.43739 216.37 7.17795C216.87 5.91851 217.623 4.77575 218.584 3.82053C219.545 2.86531 220.692 2.11825 221.955 1.62619C223.217 1.13413 224.567 0.907703 225.921 0.960667C227.267 0.917399 228.608 1.15256 229.859 1.65158C231.11 2.15061 232.244 2.90264 233.191 3.86057C234.137 4.81671 234.879 5.95493 235.373 7.20627C235.866 8.45762 236.1 9.79585 236.061 11.1404C236.009 13.1467 235.375 15.095 234.235 16.7468C233.095 18.3986 231.499 19.6829 229.641 20.4431C227.784 21.2033 225.745 21.4062 223.774 21.0276C221.803 20.6489 219.985 19.7051 218.541 18.3108V18.3108Z"
        fill="#202024"
      />
      <path
        d="M247.109 29.4302V11.0703H265.819V29.4204H278.079V44.9604H265.819V69.4702C265.752 70.1819 265.833 70.9002 266.059 71.5786C266.284 72.257 266.649 72.8806 267.129 73.4102C268.009 74.2102 269.469 74.6104 271.509 74.6104H278.229V90.4805H268.709C261.929 90.4805 256.643 88.8138 252.849 85.4805C249.056 82.1471 247.159 76.7305 247.159 69.2305V44.9702"
        fill="#202024"
      />
      <path
        d="M297.618 44.4297C299.944 39.9817 303.467 36.2722 307.788 33.7197C312.194 31.157 317.212 29.8399 322.308 29.9097C326.826 29.8795 331.278 30.9832 335.258 33.1196C339.012 35.0463 342.169 37.9612 344.388 41.5498V10.0098H354.388V90.4897H344.388V79.29C342.332 82.9392 339.326 85.963 335.688 88.04C331.599 90.39 326.944 91.5758 322.228 91.4697C317.146 91.5422 312.146 90.1837 307.799 87.5488C303.453 84.914 299.935 81.1092 297.648 76.5698C295.124 71.5843 293.857 66.057 293.958 60.4697C293.828 54.9022 295.086 49.3897 297.618 44.4297V44.4297ZM341.668 48.8398C339.992 45.6438 337.448 42.9855 334.328 41.1699C331.234 39.428 327.745 38.5088 324.195 38.5C320.644 38.4912 317.151 39.393 314.048 41.1196C310.942 42.909 308.413 45.5499 306.758 48.73C304.878 52.352 303.942 56.3898 304.038 60.4697C303.938 64.6044 304.873 68.6987 306.758 72.3799C308.412 75.5916 310.939 78.2711 314.048 80.1099C317.118 81.8895 320.611 82.8085 324.158 82.77C327.728 82.7915 331.239 81.8592 334.328 80.0698C337.459 78.2419 340.005 75.5609 341.668 72.3398C343.546 68.6952 344.481 64.6387 344.388 60.54C344.478 56.4736 343.542 52.4499 341.668 48.8398V48.8398Z"
        fill="#202024"
      />
      <path
        d="M371.469 19.2493C370.52 18.306 369.873 17.1014 369.611 15.7893C369.349 14.4772 369.483 13.1166 369.997 11.8811C370.511 10.6456 371.381 9.59127 372.496 8.85182C373.611 8.11238 374.921 7.72165 376.259 7.72926C377.122 7.71587 377.979 7.88231 378.774 8.21803C379.569 8.55375 380.286 9.0511 380.879 9.67897C382.094 10.9761 382.77 12.6869 382.77 14.4641C382.77 16.2413 382.094 17.9521 380.879 19.2493C380.292 19.8877 379.577 20.3955 378.781 20.74C377.985 21.0845 377.126 21.2582 376.259 21.2493C375.366 21.2629 374.48 21.0919 373.656 20.7478C372.831 20.4037 372.087 19.8938 371.469 19.2493V19.2493ZM381.039 30.8889V90.489H371.149V30.8889H381.039Z"
        fill="#202024"
      />
      <path
        d="M436.02 33.2801C439.627 35.2627 442.62 38.2001 444.67 41.7703V30.8899H454.67V91.8001C454.763 96.8572 453.565 101.855 451.189 106.32C448.902 110.527 445.447 113.983 441.24 116.27C436.611 118.739 431.424 119.975 426.18 119.86C418.353 119.86 411.826 118.01 406.599 114.31C404.081 112.583 401.956 110.343 400.363 107.738C398.771 105.132 397.747 102.219 397.36 99.1902H407.14C408.245 102.836 410.646 105.952 413.89 107.95C417.586 110.248 421.879 111.404 426.23 111.27C431.67 111.27 436.11 109.564 439.55 106.15C442.99 102.737 444.713 97.9534 444.719 91.8001V79.2903C442.63 82.901 439.63 85.9005 436.02 87.99C431.931 90.3743 427.261 91.5789 422.529 91.47C417.447 91.5425 412.447 90.1839 408.1 87.5491C403.754 84.9143 400.236 81.1095 397.95 76.5701C395.417 71.5871 394.147 66.0586 394.249 60.47C394.133 54.912 395.405 49.4127 397.95 44.47C400.269 40.0176 403.793 36.3066 408.12 33.7601C412.488 31.2053 417.469 29.8882 422.529 29.95C427.242 29.8447 431.897 30.994 436.02 33.2801V33.2801ZM441.95 48.8401C440.27 45.6463 437.726 42.9888 434.61 41.1702C431.514 39.4284 428.023 38.509 424.471 38.5003C420.919 38.4915 417.424 39.3934 414.319 41.1199C411.219 42.913 408.693 45.5531 407.04 48.7303C405.155 52.3506 404.219 56.3896 404.32 60.47C404.215 64.6051 405.15 68.7006 407.04 72.3802C408.693 75.589 411.215 78.2678 414.319 80.1101C417.393 81.8887 420.889 82.8077 424.439 82.7703C428.006 82.8025 431.516 81.8842 434.61 80.1101C437.738 78.2797 440.283 75.5993 441.95 72.3802C443.827 68.7355 444.762 64.6786 444.67 60.5799C444.766 56.5 443.83 52.4621 441.95 48.8401V48.8401Z"
        fill="#202024"
      />
      <path
        d="M471.75 19.2494C470.802 18.3067 470.155 17.1031 469.893 15.7919C469.63 14.4808 469.764 13.1212 470.276 11.8862C470.789 10.6511 471.657 9.5961 472.771 8.85589C473.884 8.11568 475.193 7.72381 476.53 7.72942C477.395 7.71463 478.253 7.88048 479.051 8.21624C479.848 8.552 480.566 9.05001 481.16 9.67913C482.375 10.9763 483.051 12.6871 483.051 14.4643C483.051 16.2415 482.375 17.9523 481.16 19.2494C480.572 19.8886 479.855 20.3966 479.058 20.7411C478.26 21.0857 477.399 21.2591 476.53 21.2494C475.639 21.2628 474.754 21.0921 473.931 20.748C473.109 20.4038 472.366 19.8937 471.75 19.2494V19.2494ZM481.32 30.8891V90.4892H471.42V30.8891H481.32Z"
        fill="#202024"
      />
      <path
        d="M496.57 30.8906V15.8906H506.47V30.8906H522.02V39.0708H506.47V74.2007C506.47 77.1007 507.08 79.2008 508.32 80.3408C509.56 81.4808 511.69 82.1406 514.73 82.1406H522.02V90.5107H513.1C507.594 90.5107 503.46 89.2443 500.7 86.7109C497.94 84.1776 496.564 80.0074 496.57 74.2007V39.0708"
        fill="#202024"
      />
      <path
        d="M533.979 44.43C536.305 39.982 539.827 36.2725 544.149 33.7201C548.518 31.1663 553.499 29.8492 558.559 29.91C563.312 29.7958 568.009 30.9597 572.159 33.2801C575.742 35.2756 578.712 38.2113 580.749 41.7704V30.89H590.749V90.4901H580.749V79.4002C578.657 83.0106 575.636 85.9943 571.999 88.0404C567.877 90.391 563.193 91.5764 558.449 91.4701C553.394 91.5359 548.422 90.1815 544.099 87.5604C539.775 84.8976 536.273 81.0905 533.979 76.5604C531.455 71.5748 530.188 66.0476 530.289 60.4603C530.17 54.8936 531.438 49.3844 533.979 44.43V44.43ZM577.979 48.8402C576.302 45.6442 573.758 42.9858 570.639 41.1703C567.545 39.4284 564.056 38.5091 560.505 38.5003C556.955 38.4916 553.461 39.3934 550.359 41.12C547.253 42.9093 544.723 45.5502 543.069 48.7303C541.188 52.3523 540.253 56.3902 540.349 60.4701C540.249 64.6047 541.184 68.699 543.069 72.3802C544.723 75.592 547.249 78.2715 550.359 80.1102C553.428 81.8898 556.921 82.8089 560.469 82.7704C564.035 82.804 567.546 81.8856 570.639 80.1102C573.77 78.2823 576.315 75.6012 577.979 72.3802C579.856 68.7356 580.791 64.6786 580.699 60.5799C580.81 56.5043 579.892 52.4667 578.029 48.8402H577.979Z"
        fill="#202024"
      />
      <path
        d="M617.402 10.0098V90.4897H607.512V10.0098H617.402Z"
        fill="#202024"
      />
      <path
        d="M139.42 18.3108C138.483 17.3715 137.746 16.2513 137.254 15.0188C136.763 13.7863 136.527 12.4668 136.56 11.1404C136.515 9.78624 136.75 8.43739 137.249 7.17795C137.749 5.91851 138.502 4.77575 139.463 3.82053C140.424 2.86531 141.571 2.11825 142.834 1.62619C144.096 1.13413 145.446 0.907703 146.8 0.960667C148.148 0.91842 149.489 1.15412 150.742 1.65305C151.994 2.15198 153.131 2.90334 154.08 3.86057C155.022 4.81959 155.76 5.95838 156.251 7.2092C156.743 8.46002 156.977 9.79698 156.94 11.1404C156.888 13.1467 156.254 15.095 155.114 16.7468C153.974 18.3986 152.377 19.6829 150.52 20.4431C148.662 21.2033 146.624 21.4062 144.653 21.0276C142.682 20.6489 140.864 19.7051 139.42 18.3108V18.3108Z"
        fill="#202024"
      />
    </Box>
  )
}

export default Logo
