import { Box } from '@chakra-ui/react'
import { motion, useDomEvent } from 'framer-motion'
import React, { useRef, useState } from 'react'

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120
}

const ImageLightbox = ({ url }) => {
  const [isOpen, setOpen] = useState(false)

  let ref = undefined
  if (typeof window !== 'undefined') {
    ref = window
  }
  useDomEvent(useRef(ref), 'scroll', () => isOpen && setOpen(false))

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '0',
        paddingTop: '66.66%',
        cursor: isOpen ? 'zoom-out' : 'zoom-in',
        margin: '50px 0'
      }}
    >
      <motion.div
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={transition}
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          pointerEvents: isOpen ? 'auto' : 'none',
          opacity: isOpen ? '1' : '0',
          background: 'rgba(0, 0, 0, 0.9)'
        }}
        onClick={() => setOpen(false)}
      />
      <motion.img
        src={url}
        alt="Bimhuis in Amsterdam"
        onClick={() => setOpen(!isOpen)}
        layout
        sx={{
          position: isOpen ? 'fixed' : 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          width: isOpen ? 'auto' : '100%',
          height: isOpen ? 'auto' : '100%',
          maxWidth: '100%',
          margin: 'auto'
        }}
        transition={transition}
      />
    </Box>
  )
}

export default ImageLightbox
