import React from 'react'
import { Box } from '@chakra-ui/react'

const SeoSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 952 673"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.3711 369.393V500.923C43.3711 512.493 44.5411 523.783 46.7611 534.693C48.0711 541.103 49.7311 547.383 51.7611 553.513C60.0811 578.863 74.2611 601.553 92.7011 620.003C121.151 648.453 159.751 666.773 202.591 669.073C204.101 669.163 205.611 669.223 207.141 669.263H207.161C208.691 669.313 210.231 669.333 211.771 669.333C213.091 669.333 214.391 669.313 215.701 669.283H215.791C217.281 669.253 218.761 669.193 220.241 669.113C227.891 668.743 235.401 667.843 242.751 666.483C261.871 662.923 279.841 656.143 296.111 646.713C346.381 617.583 380.181 563.193 380.181 500.923V249.203"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M386.291 65.6502C459.301 65.3602 532.311 65.0602 605.321 64.7702C613.421 64.7402 626.661 64.6502 634.511 66.6202C657.621 72.4202 668.611 95.1202 672.761 118.59C676.911 142.05 675.481 167.98 667.701 190.5C663.801 201.8 654.661 211.67 645.161 218.93C635.531 226.29 485.991 222.88 473.891 223.52C398.121 227.53 451.551 223.27 383.741 228.32C377.441 186.89 382.251 147.21 380.171 105.36C379.751 96.9902 382.561 85.8002 385.251 77.8702C387.941 69.9402 395.321 62.8602 403.661 63.6002L386.281 65.6602L386.291 65.6502Z"
      fill="#D8D8D8"
    />
    <path
      d="M539.19 205.29C556.051 205.29 569.72 178.194 569.72 144.77C569.72 111.346 556.051 84.25 539.19 84.25C522.329 84.25 508.66 111.346 508.66 144.77C508.66 178.194 522.329 205.29 539.19 205.29Z"
      fill="white"
    />
    <path
      d="M627.401 205.29C644.262 205.29 657.931 178.194 657.931 144.77C657.931 111.346 644.262 84.25 627.401 84.25C610.54 84.25 596.871 111.346 596.871 144.77C596.871 178.194 610.54 205.29 627.401 205.29Z"
      fill="white"
    />
    <path
      d="M815.16 257.031H487.73L484.25 224.831L637.7 220.531L671.84 194.891L815.16 196.181V257.031Z"
      fill="#D8D8D8"
    />
    <path
      d="M817.18 502.31V204.65C817.18 199.15 812.25 194.66 806.22 194.66L664.66 194.78"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M487.398 544.121H492.698H522.458"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M700.149 431.891C699.019 432.691 698.439 434.041 697.999 435.351C697.059 438.201 696.569 441.201 696.559 444.201C696.559 446.151 696.779 448.211 697.909 449.811C698.839 451.131 700.279 451.981 701.689 452.771C702.489 453.221 703.299 453.661 704.189 453.881C706.809 454.531 703.289 467.151 705.909 466.491C707.269 466.151 715.229 468.311 716.599 468.011C719.919 467.291 728.939 477.441 731.979 475.921C734.529 474.651 735.779 448.011 736.599 445.281C737.049 443.771 732.019 439.231 731.549 437.721C730.689 434.971 728.929 432.311 726.249 431.251C724.899 430.721 723.409 430.631 721.959 430.541C716.029 430.201 710.019 429.871 704.189 431.001L700.159 431.891H700.149Z"
      fill="#D8D8D8"
    />
    <path
      d="M695.441 449.202V337.162C695.441 335.912 696.451 334.902 697.701 334.902H733.981C735.231 334.902 736.241 335.912 736.241 337.162V443.432"
      fill="#D8D8D8"
    />
    <path
      d="M695.441 449.202V337.162C695.441 335.912 696.451 334.902 697.701 334.902H733.981C735.231 334.902 736.241 335.912 736.241 337.162V443.432"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M479.148 543.643V543.663C478.088 547.743 476.878 551.743 475.518 555.683C469.218 574.033 459.828 590.933 447.988 605.803H870.458C867.678 559.213 840.328 515.213 800.958 490.493C792.428 485.133 783.338 480.673 773.818 477.293C768.318 475.343 762.728 473.733 757.088 472.243C746.628 469.473 736.038 467.123 725.728 463.843C725.678 463.823 725.618 463.813 725.568 463.793C720.188 462.073 714.838 460.063 709.708 457.633C708.078 456.863 706.458 456.043 704.878 455.183C701.598 453.403 695.448 449.213 695.448 449.213C691.108 446.023 687.168 442.353 683.778 438.113C681.698 435.503 679.868 432.743 678.198 429.863C672.898 420.733 669.258 410.473 664.668 400.933C661.878 395.133 658.738 389.583 654.648 384.733C653.618 383.493 652.508 382.303 651.338 381.153C646.038 375.973 639.868 372.323 633.148 369.603C626.378 366.853 619.058 365.053 611.548 363.603C611.158 363.523 610.758 363.433 610.368 363.373C600.878 361.553 591.098 360.223 581.748 358.143C568.948 355.283 556.948 351.033 547.558 342.213C537.848 333.103 532.478 320.343 524.228 309.893C514.348 297.383 500.078 288.603 484.628 284.773V495.483"
      fill="#7A6CFF"
    />
    <path
      d="M479.148 543.643V543.663C478.088 547.743 476.878 551.743 475.518 555.683C469.218 574.033 459.828 590.933 447.988 605.803H870.458C867.678 559.213 840.328 515.213 800.958 490.493C792.428 485.133 783.338 480.673 773.818 477.293C768.318 475.343 762.728 473.733 757.088 472.243C746.628 469.473 736.038 467.123 725.728 463.843C725.678 463.823 725.618 463.813 725.568 463.793C720.188 462.073 714.838 460.063 709.708 457.633C708.078 456.863 706.458 456.043 704.878 455.183C701.598 453.403 695.448 449.213 695.448 449.213C691.108 446.023 687.168 442.353 683.778 438.113C681.698 435.503 679.868 432.743 678.198 429.863C672.898 420.733 669.258 410.473 664.668 400.933C661.878 395.133 658.738 389.583 654.648 384.733C653.618 383.493 652.508 382.303 651.338 381.153C646.038 375.973 639.868 372.323 633.148 369.603C626.378 366.853 619.058 365.053 611.548 363.603C611.158 363.523 610.758 363.433 610.368 363.373C600.878 361.553 591.098 360.223 581.748 358.143C568.948 355.283 556.948 351.033 547.558 342.213C537.848 333.103 532.478 320.343 524.228 309.893C514.348 297.383 500.078 288.603 484.628 284.773V495.483"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M817.179 255.363H484.629"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M786.59 232.211C790.042 232.211 792.84 229.413 792.84 225.961C792.84 222.509 790.042 219.711 786.59 219.711C783.138 219.711 780.34 222.509 780.34 225.961C780.34 229.413 783.138 232.211 786.59 232.211Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M756.641 232.211C760.092 232.211 762.891 229.413 762.891 225.961C762.891 222.509 760.092 219.711 756.641 219.711C753.189 219.711 750.391 222.509 750.391 225.961C750.391 229.413 753.189 232.211 756.641 232.211Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M726.691 232.211C730.143 232.211 732.941 229.413 732.941 225.961C732.941 222.509 730.143 219.711 726.691 219.711C723.24 219.711 720.441 222.509 720.441 225.961C720.441 229.413 723.24 232.211 726.691 232.211Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M900.149 557.912C907.765 557.912 913.939 551.738 913.939 544.122C913.939 536.506 907.765 530.332 900.149 530.332C892.533 530.332 886.359 536.506 886.359 544.122C886.359 551.738 892.533 557.912 900.149 557.912Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M847.768 487.15C850.872 487.15 853.388 484.634 853.388 481.53C853.388 478.426 850.872 475.91 847.768 475.91C844.665 475.91 842.148 478.426 842.148 481.53C842.148 484.634 844.665 487.15 847.768 487.15Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M905.39 461.69C910.112 461.69 913.94 457.862 913.94 453.14C913.94 448.418 910.112 444.59 905.39 444.59C900.668 444.59 896.84 448.418 896.84 453.14C896.84 457.862 900.668 461.69 905.39 461.69Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M583.06 326.631C586.583 326.631 589.44 323.775 589.44 320.251C589.44 316.728 586.583 313.871 583.06 313.871C579.536 313.871 576.68 316.728 576.68 320.251C576.68 323.775 579.536 326.631 583.06 326.631Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M712.96 555.484V605.805H448C459.84 590.945 469.22 574.035 475.53 555.695L478.37 550.645H708.12C710.8 550.645 712.97 552.815 712.97 555.495L712.96 555.484Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M478.371 550.631H667.721V500.311C667.721 497.631 665.551 495.461 662.871 495.461H484.631"
      fill="#D8D8D8"
    />
    <path
      d="M478.371 550.631H667.721V500.311C667.721 497.631 665.551 495.461 662.871 495.461H484.631"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M484.629 495.475H610.369V445.155C610.369 442.475 608.199 440.305 605.519 440.305H485.159"
      fill="#D8D8D8"
    />
    <path
      d="M484.629 495.475H610.369V445.155C610.369 442.475 608.199 440.305 605.519 440.305H485.159"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M485.658 440.303H554.508V389.983C554.508 387.303 552.338 385.133 549.658 385.133H485.648"
      fill="#D8D8D8"
    />
    <path
      d="M485.658 440.303H554.508V389.983C554.508 387.303 552.338 385.133 549.658 385.133H485.648"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M448 605.791C459.84 590.931 469.22 574.021 475.53 555.681C476.89 551.721 478.1 547.731 479.16 543.661C482.74 530.001 484.64 515.691 484.64 500.921V226.391"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M52.1996 319.883C52.0296 325.793 45.0296 328.923 39.1396 329.353C33.2496 329.783 26.6796 329.133 22.0896 332.863C17.6296 336.493 16.8096 342.873 16.3196 348.603C15.9796 352.553 15.6996 356.773 17.5996 360.253C20.9596 366.393 26.5196 369.163 33.5096 369.393C87.9696 371.163 145.69 372.633 200.08 369.393C202.49 369.253 211.83 371.853 213.98 370.773C219.85 367.843 213.35 353.173 213.09 346.623C212.44 330.403 214.18 315.213 213.46 298.993C211.81 262.023 216.43 224.313 214.86 187.333C214.68 183.063 215.83 146.713 213.46 143.143C210.93 139.343 202.44 143.783 197.97 142.803C189.55 140.963 172.36 142.723 163.92 144.503C146.48 148.193 125.27 159.043 109.74 167.793C101.07 172.683 89.3396 186.413 81.5996 192.673C75.5796 197.553 72.2196 208.503 66.8896 214.133C59.4896 221.943 57.0596 233.353 52.0296 242.873C39.8796 265.903 44.9996 294.123 43.6196 320.123C43.4596 323.063 43.0996 326.493 40.5696 327.993L52.1796 319.873L52.1996 319.883Z"
      fill="#7A6CFF"
    />
    <path
      d="M214.478 47.8711V369.381H27.6081C22.4781 369.381 18.3281 365.221 18.3281 360.101V338.451C18.3281 333.321 22.4881 329.171 27.6081 329.171H182.238"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M214.478 352.07V522.92C214.478 535.79 210.428 548.34 202.898 558.78"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M214.48 352.07V522.92C214.48 535.79 218.53 548.34 226.06 558.78"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M339.59 144.503V40.2625C339.59 20.8225 355.35 5.0625 374.79 5.0625C394.23 5.0625 409.99 20.8225 409.99 40.2625V64.0125"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M352.23 12.6725C346.22 7.9125 338.62 5.0625 330.36 5.0625C322.1 5.0625 314.65 7.8525 308.67 12.5225C300.44 18.9725 295.16 29.0025 295.16 40.2625V144.503"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M309.851 12.5225C303.871 7.8525 296.341 5.0625 288.161 5.0625C279.981 5.0625 272.451 7.8525 266.471 12.5225C258.241 18.9725 252.961 29.0025 252.961 40.2625V144.503"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M214.48 200.06V40.2703C214.48 20.8303 230.24 5.07031 249.68 5.07031C257.86 5.07031 264.43 7.14031 270.41 11.8103"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M447.77 65.7634V38.7234C447.77 27.4534 442.48 17.4334 434.26 10.9834C428.28 6.31344 420.75 3.52344 412.57 3.52344C404.39 3.52344 397.75 5.64343 391.77 10.3134"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M430.941 8.67172C436.311 5.22172 442.411 3.51172 449.431 3.51172C468.871 3.51172 484.631 19.2717 484.631 38.7117V65.7517"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M380.18 39.8203V249.19"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M379.422 64.6406H628.892"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M378.809 224.891H628.889"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M601.769 171.641C606.719 191.581 616.999 205.281 628.889 205.281C640.779 205.281 651.059 191.581 656.009 171.641M672.569 120.471C666.679 88.0906 649.349 64.6406 628.889 64.6406C608.429 64.6406 591.089 88.0906 585.209 120.471C583.809 128.131 583.059 136.301 583.059 144.761C583.059 154.191 583.989 163.241 585.709 171.641C592.029 202.671 608.979 224.891 628.899 224.891C648.819 224.891 665.769 202.671 672.089 171.641C673.799 163.241 674.739 154.191 674.739 144.761C674.739 136.291 673.989 128.131 672.589 120.471H672.569Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M506.968 144.761C506.968 167.781 513.398 187.791 522.858 198.031C527.128 202.661 532.018 205.281 537.228 205.281C549.118 205.281 559.398 191.581 564.348 171.641V171.631C566.368 163.531 567.498 154.401 567.498 144.751C567.498 136.111 566.588 127.891 564.958 120.461C560.278 99.1306 549.618 84.2406 537.228 84.2406C520.518 84.2406 506.968 111.341 506.968 144.761ZM506.968 144.761C506.968 111.341 520.518 84.2406 537.238 84.2406C549.628 84.2406 560.288 99.1306 564.968 120.461M580.908 120.471C575.018 88.0906 557.688 64.6406 537.228 64.6406C511.918 64.6406 491.398 100.521 491.398 144.761C491.398 180.241 504.588 210.331 522.858 220.871C527.378 223.481 532.218 224.891 537.228 224.891C557.148 224.891 574.098 202.671 580.418 171.641C582.128 163.241 583.068 154.191 583.068 144.761C583.068 136.291 582.318 128.131 580.918 120.471H580.908Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M656.011 171.642C658.031 163.542 659.161 154.412 659.161 144.762C659.161 111.342 645.611 84.2422 628.891 84.2422C612.171 84.2422 598.621 111.332 598.621 144.762C598.621 154.412 599.751 163.542 601.771 171.642"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M43.3711 329.171V293.701C43.3711 209.441 111.671 141.141 195.931 141.141H214.481V235.331"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M472.598 225.963H382.648V308.843V293.803C382.648 257.103 412.398 227.353 449.098 227.353H472.608V225.953L472.598 225.963Z"
      fill="#383838"
    />
    <path
      d="M439.099 66.3203H382.609V118.37V108.93C382.609 85.8803 401.289 67.2003 424.339 67.2003H439.099V66.3203Z"
      fill="#383838"
    />
    <path
      d="M101.91 368.551H213.46V471.331V452.681C213.46 407.171 176.57 370.281 131.06 370.281H101.91V368.551Z"
      fill="#383838"
    />
    <path
      d="M112.911 329.082H44.2811L43.3711 296.152L45.7611 298.762C45.7611 326.762 66.9811 328.012 94.9811 328.012H112.911V329.082Z"
      fill="#383838"
    />
    <path
      d="M581.47 165.183C580.52 169.283 578.8 180.673 577.04 184.503C575.57 187.703 575.21 191.123 573.35 194.103C571.74 196.673 571.72 199.293 569.86 201.683C564.41 208.673 559.68 217.503 546.84 224.203C548.06 225.033 556.01 223.463 557.48 223.613C576.56 225.523 603.67 226.193 622.8 224.903C615.35 221.883 606.35 215.123 602.05 210.403C599.27 207.353 598.64 205.173 596.18 201.863C590.86 194.703 586.45 171.493 584.09 162.883L581.48 165.193L581.47 165.183Z"
      fill="#383838"
    />
    <path
      d="M80.19 605.793H0"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M951.671 605.793H343.551"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M757.079 472.243C746.619 469.473 736.029 467.123 725.719 463.843C725.669 463.823 725.609 463.813 725.559 463.793"
      fill="white"
    />
    <path
      d="M757.079 472.243C746.619 469.473 736.029 467.123 725.719 463.843C725.669 463.823 725.609 463.813 725.559 463.793"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M695.441 442.042V286.502C695.441 285.252 694.431 284.242 693.181 284.242H656.901C655.651 284.242 654.641 285.252 654.641 286.502V384.722"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M736.238 468.013V382.713C736.238 381.463 737.248 380.453 738.498 380.453H774.778C776.028 380.453 777.038 381.463 777.038 382.713V478.573"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M214.482 63.1016V225.912C195.682 225.912 180.422 210.652 180.422 191.852V97.1716C180.422 78.3716 195.682 63.1116 214.482 63.1116V63.1016Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M529.051 86.4805C542.111 93.8305 551.661 117.14 551.661 144.77C551.661 172.4 542.291 195.28 529.411 202.85"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M620.91 86.4805C633.97 93.8305 643.52 117.14 643.52 144.77C643.52 172.4 634.15 195.28 621.27 202.85"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M537.23 85.2306C542.79 84.5806 548.34 91.4506 552.83 94.7806C554.83 96.2606 556.85 97.7806 558.33 99.7806C560.92 103.291 561.52 107.851 562.02 112.181C562.88 119.541 567.54 127.361 568.41 134.721C569.14 140.961 569.32 147.621 569.07 153.901C568.71 162.811 564.87 172.191 562.55 180.801C561.65 184.151 559.01 186.001 557.43 189.091C553.67 196.461 546.5 201.491 539.11 205.221C538.18 205.691 537.14 206.161 536.12 205.921C534.2 205.481 533.67 202.801 534.55 201.031C535.43 199.261 537.15 198.121 538.55 196.731C541.97 193.341 543.43 188.511 545.59 184.211C547.74 179.921 547.14 174.781 548.76 170.261C550.14 166.381 552.23 163.221 552.58 159.111C553.42 149.031 554.63 139.321 552.58 129.411C551.49 124.131 551.01 118.761 549.04 113.741C545.63 105.051 538.51 93.7706 532.68 86.4706"
      fill="#383838"
    />
    <path
      d="M628.891 84.2502C634.451 83.6002 638.841 90.4502 643.331 93.7902C645.331 95.2702 647.351 96.7902 648.831 98.7902C651.421 102.3 652.021 106.86 652.521 111.19C653.381 118.55 657.071 125.46 657.941 132.82C658.671 139.06 659.171 146.1 658.921 152.38C658.561 161.29 660.681 172.42 652.781 181.77C650.541 184.42 649.021 184.81 647.941 188.1C644.681 198.06 637.811 204.65 629.621 204.23C628.581 204.18 627.651 205.17 626.631 204.93C624.711 204.49 624.181 201.81 625.061 200.04C625.941 198.28 627.661 197.13 629.061 195.74C632.481 192.35 633.941 187.52 636.101 183.22C638.251 178.93 641.181 175.01 642.791 170.49C644.171 166.61 644.531 162.45 644.871 158.35C645.711 148.27 646.541 138.03 644.491 128.13C643.401 122.85 641.511 117.78 639.541 112.76C636.131 104.07 630.471 94.8102 624.641 87.5202"
      fill="#383838"
    />
    <path
      d="M691.66 449.48L695.69 452.46C695.69 452.46 692.74 453.9 692.49 454.05"
      fill="#7A6CFF"
    />
  </Box>
)

export default SeoSVG
