import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const md = defineStyle({
  minW: null,
  h: null,
  fontSize: 'sm',
  py: 'sm',
  px: '2.5rem',
  fontWeight: 'bold'
})
const lg = defineStyle({
  minW: null,
  h: null,
  fontSize: 'sm',
  py: '1.625rem',
  px: '2xl'
})
const xl = defineStyle({
  minW: null,
  h: null,
  fontSize: 'sm',
  py: 'lg',
  px: '2xl'
})
const solid = defineStyle(props => {
  const { colorScheme: c } = props
  return {
    color: c === 'brand' ? 'white' : 'black',
    bg: c === 'brand' ? 'brand.600' : 'gray.300',
    borderRadius: 'base',
    lineHeight: 'none',
    transition: 'background 200ms linear',
    _hover: {
      // bg: 'blackAlpha.100',
      textDecoration: 'none',
      bg: c === 'brand' ? 'brand.500' : 'gray.200'
    },
    _focus: {
      textDecoration: 'none'
    }
  }
})
const tab = defineStyle(props => {
  const { colorScheme: c, bg } = props
  return {
    px: 2,
    minW: '12rem',
    color: c === 'brand' ? 'white' : 'black',
    bg: bg === 'white' ? 'white' : 'gray.200',
    borderRadius: 'xs',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: bg === 'white' ? 'white' : 'gray.200',
    lineHeight: 'none',
    _hover: {
      // bg: 'blackAlpha.100',
      borderColor: 'brand.600',
      bg: bg === 'white' ? 'white' : 'gray.200',
      textDecoration: 'none'
    },
    _focus: {
      borderColor: 'brand.600',
      bg: bg === 'white' ? 'white' : 'gray.200',
      textDecoration: 'none'
    }
  }
})
const link = defineStyle({
  minW: null,
  color: 'brand.500',
  bg: null
})
// eslint-disable-next-line import/no-anonymous-default-export
export default defineStyleConfig({
  sizes: {
    md,
    lg,
    xl
  },
  variants: {
    solid,
    link,
    tab
  },
  defaultProps: {
    size: 'md',
    variant: 'solid'
  }
})
