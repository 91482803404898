import { Link } from 'gatsby'
import React from 'react'

const LanguageSwitcher = ({ locale, translations }) => {
  const { locale: currentLocale } = locale
  const localesToDisplay = translations.filter(
    item => item.locale !== currentLocale
  )
  return (
    <div className="absolute top-0 right-0 mt-lg mr-lg text-white z-20">
      {localesToDisplay &&
        localesToDisplay.map((locale, index) => (
          <Link
            key={`language${index}`}
            to={`${locale.href.replace('/all/', '/')}`}
            className="p-md uppercase text-xl"
          >
            world icon
            {locale.locale}
          </Link>
        ))}
    </div>
  )
}

export default LanguageSwitcher

// export const query = graphql`
//   fragment PostLanguageSwitcher on WpPost {
//       locale{
//         id
//         locale
//       }
//       translations{
//         id
//         locale
//         href
//       }
//   }
//   fragment PageLanguageSwitcher on WpPage {
//       locale{
//         id
//         locale
//       }
//       translations{
//         id
//         locale
//         href
//       }
//   }
// `
