export default {
  base: '0em',
  sm: '480px',
  md: '768px',
  lg: '1280px',
  xl: '1480px',
  '2xl': '1800px'
  /*  "base": "0em",
  "sm": "30em",
  "md": "48em",
  "lg": "62em",
  "xl": "80em",
  "2xl": "96em"*/
}
