import { graphql, useStaticQuery } from 'gatsby'
import { useContext } from 'react'
import { PageContext } from '../context'

const useHomepage = () => {
  const pageContext = useContext(PageContext)
  const { allSanityHomepage } = useStaticQuery(
    graphql`
      query {
        allSanityHomepage {
          nodes {
            i18n_lang
            services_promo
            services_featured {
              _id
              title
              short_title
              _rawShortContent(resolveReferences: { maxDepth: 10 })
              path
              illustration
              icon
            }
            services_additional_title
            services_additional {
              _id
              title
              short_title
              path
              icon
            }
            showcase_title
            showcase_projects {
              _id
              ...ShowcaseProjectCard
            }
            trusted_title
            trusted_by {
              _key
              title
              website
              logo {
                asset {
                  _id
                  width
                  height
                }
              }
            }
          }
        }
      }
    `
  )
  const homepage = allSanityHomepage.nodes.find(
    node => node.i18n_lang === pageContext.i18n_lang
  )
  if (!homepage) {
    console.warn('No homepage language found!', pageContext.i18n_lang)
  }
  return homepage
}

export default useHomepage
