import React from 'react'
import { Box } from '@chakra-ui/react'

const DevelopmentSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 1009 625"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1164_780)">
      <path
        d="M572.68 125.258C561.7 130.448 547.36 139.268 536.46 154.518C501.54 203.378 537.27 268.718 501.8 301.118C495.94 306.478 487.19 311.778 473.75 314.308L494.8 433.218C582.13 436.938 610.78 370.048 607.07 323.598C603.15 274.538 551.52 238.578 564.34 184.248C568.76 165.488 578.88 151.598 586.64 143.368"
        fill="#D8D8D8"
      />
      <path
        d="M572.68 125.258C561.7 130.448 547.36 139.268 536.46 154.518C501.54 203.378 537.27 268.718 501.8 301.118C495.94 306.478 487.19 311.778 473.75 314.308L494.8 433.218C582.13 436.938 610.78 370.048 607.07 323.598C603.15 274.538 551.52 238.578 564.34 184.248C568.76 165.488 578.88 151.598 586.64 143.368"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M680.959 122.359L657.639 187.499C655.459 193.599 647.369 194.819 643.479 189.639L623.859 163.479"
        fill="white"
      />
      <path
        d="M680.959 122.359L657.639 187.499C655.459 193.599 647.369 194.819 643.479 189.639L623.859 163.479"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M614.899 34.2891L545.839 38.4391C539.369 38.8291 535.939 46.2491 539.819 51.4391L559.439 77.5991"
        fill="white"
      />
      <path
        d="M614.899 34.2891L545.839 38.4391C539.369 38.8291 535.939 46.2491 539.819 51.4391L559.439 77.5991"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M575.679 133.96L551.929 102.29C546.489 95.0397 547.959 84.7397 555.209 79.2997L627.309 25.2197C627.309 25.2197 671.869 -12.9303 726.439 10.7297C730.859 12.6497 733.799 16.9097 734.159 21.7097C735.239 36.0897 734.479 62.4797 717.879 87.1997C709.789 99.2497 700.309 107.37 692.849 112.59L620.749 166.67C613.499 172.11 603.199 170.64 597.759 163.39L575.679 133.96Z"
        fill="#7A6CFF"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M578.277 62.0095L627.307 25.2295C627.307 25.2295 671.867 -12.9205 726.437 10.7395C730.857 12.6595 733.797 16.9195 734.157 21.7195C735.237 36.0995 734.477 62.4895 717.877 87.2095C709.787 99.2595 700.307 107.379 692.847 112.599L643.817 149.379"
        fill="white"
      />
      <path
        d="M578.277 62.0095L627.307 25.2295C627.307 25.2295 671.867 -12.9205 726.437 10.7395C730.857 12.6595 733.797 16.9195 734.157 21.7195C735.237 36.0995 734.477 62.4895 717.877 87.2095C709.787 99.2595 700.307 107.379 692.847 112.599L643.817 149.379"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M677.641 80.3789C691.034 80.3789 701.891 69.5218 701.891 56.1289C701.891 42.736 691.034 31.8789 677.641 31.8789C664.248 31.8789 653.391 42.736 653.391 56.1289C653.391 69.5218 664.248 80.3789 677.641 80.3789Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M644.221 149.381L579.341 61.8008L578.281 62.0108L555.221 79.3108C547.971 84.7508 546.501 95.0408 551.941 102.301L575.691 133.971L597.771 163.401C603.211 170.651 613.501 172.121 620.761 166.681L643.821 149.381"
        fill="#7A6CFF"
      />
      <path
        d="M644.221 149.381L579.341 61.8008L578.281 62.0108L555.221 79.3108C547.971 84.7508 546.501 95.0408 551.941 102.301L575.691 133.971L597.771 163.401C603.211 170.651 613.501 172.121 620.761 166.681L643.821 149.381"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M643.249 184.158C642.239 181.958 638.109 172.368 641.909 161.198C644.619 153.228 649.949 148.618 652.269 146.828C643.049 152.128 633.829 157.428 624.609 162.728C630.819 169.868 637.029 177.018 643.239 184.158H643.249Z"
        fill="#383838"
      />
      <path
        d="M544.801 52.5586C546.631 54.1486 554.681 60.7886 566.471 60.2686C574.881 59.8986 580.801 56.0686 583.161 54.3486C575.491 61.7186 567.821 69.0786 560.161 76.4486C555.041 68.4886 549.921 60.5286 544.801 52.5586Z"
        fill="#383838"
      />
      <path
        d="M930.902 330.21C933.562 324.32 935.092 318.9 936.012 314.5C945.142 314.66 954.272 314.82 963.402 314.98V256.61H935.412C934.252 252.66 932.642 248.16 930.392 243.33C928.002 238.2 925.422 233.85 923.012 230.27L943.872 209.37L902.682 168.09L882.502 188.31C878.352 186.36 873.812 184.42 868.892 182.58C863.832 180.68 859.012 179.11 854.492 177.82C854.842 168.05 855.192 158.29 855.532 148.52H797.272V178.39C793.062 179.39 788.212 180.83 782.992 182.95C778.062 184.96 773.802 187.16 770.232 189.26C763.532 182.2 756.832 175.14 750.132 168.08L708.942 209.36L729.582 230.04C727.392 233.4 725.072 237.45 722.922 242.17C720.522 247.46 718.892 252.37 717.772 256.6C708.322 256.6 698.872 256.6 689.422 256.6V314.97H716.962C718.032 319.31 719.522 324.21 721.622 329.45C723.522 334.21 725.582 338.42 727.592 342.04C721.382 348.76 715.172 355.48 708.962 362.21L750.152 403.49L768.742 384.87C772.682 387.11 777.232 389.41 782.382 391.55C787.892 393.84 793.052 395.53 797.662 396.78C797.542 405.54 797.422 414.3 797.302 423.06H855.562V397.29C860.232 395.67 865.132 393.81 870.232 391.65C874.822 389.71 879.132 387.71 883.162 385.7C889.682 391.63 896.192 397.55 902.712 403.48L943.902 362.2L924.062 342.32C926.302 339.1 928.742 335.06 930.932 330.19L930.902 330.21Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M826.401 342.771C856.782 342.771 881.411 318.093 881.411 287.651C881.411 257.209 856.782 232.531 826.401 232.531C796.019 232.531 771.391 257.209 771.391 287.651C771.391 318.093 796.019 342.771 826.401 342.771Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M835.31 621.512V314.542C835.31 281.252 808.32 254.262 775.03 254.262H723.87L678.68 404.652V620.872L835.31 621.512Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M655.219 255.521V301.281C655.219 301.951 654.679 302.491 654.009 302.491H566.039C554.809 302.491 545.699 293.381 545.699 282.151V274.641C545.699 263.411 554.809 254.301 566.039 254.301H654.009C654.679 254.301 655.219 254.841 655.219 255.511V255.521Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M8.37109 621.512H1008.86"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M708.812 586.109H82.6717C73.8849 586.109 66.7617 593.233 66.7617 602.019V605.389C66.7617 614.176 73.8849 621.299 82.6717 621.299H708.812C717.599 621.299 724.722 614.176 724.722 605.389V602.019C724.722 593.233 717.599 586.109 708.812 586.109Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M61.0898 192.891H434.01C444.27 192.891 453.08 200.171 455.02 210.241L521.31 585.451H139.68"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M146.871 585.441H531.881"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M146.869 585.441C120.529 585.441 97.8886 566.751 92.8986 540.881L36.7586 218.331C34.2186 205.131 44.3286 192.891 57.7686 192.891H61.0986H24.3986C10.9586 192.891 0.848596 205.141 3.3886 218.331L61.2186 549.651C65.2186 570.431 83.4086 585.441 104.569 585.441H139.679"
        fill="#D8D8D8"
      />
      <path
        d="M146.869 585.441C120.529 585.441 97.8886 566.751 92.8986 540.881L36.7586 218.331C34.2186 205.131 44.3286 192.891 57.7686 192.891H61.0986H24.3986C10.9586 192.891 0.848596 205.141 3.3886 218.331L61.2186 549.651C65.2186 570.431 83.4086 585.441 104.569 585.441H139.679"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M57.7619 192.891C44.3219 192.891 34.2119 205.141 36.7519 218.331L45.7419 267.581H47.1619H464.732L465.002 264.611L455.022 210.241C453.082 200.171 444.272 192.891 434.012 192.891H57.7619Z"
        fill="#7A6CFF"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M343.431 239.741C347.932 239.741 351.581 236.093 351.581 231.591C351.581 227.09 347.932 223.441 343.431 223.441C338.93 223.441 335.281 227.09 335.281 231.591C335.281 236.093 338.93 239.741 343.431 239.741Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M380.31 239.741C384.811 239.741 388.46 236.093 388.46 231.591C388.46 227.09 384.811 223.441 380.31 223.441C375.809 223.441 372.16 227.09 372.16 231.591C372.16 236.093 375.809 239.741 380.31 239.741Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M418.22 239.741C422.721 239.741 426.37 236.093 426.37 231.591C426.37 227.09 422.721 223.441 418.22 223.441C413.719 223.441 410.07 227.09 410.07 231.591C410.07 236.093 413.719 239.741 418.22 239.741Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M253.141 566.32H281.751C290.821 566.32 298.181 573.68 298.181 582.75V585.78H236.711V582.75C236.711 573.68 244.071 566.32 253.141 566.32Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M330.801 566.32H359.411C368.481 566.32 375.841 573.68 375.841 582.75V585.78H314.371V582.75C314.371 573.68 321.731 566.32 330.801 566.32Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M408.09 566.32H436.7C445.77 566.32 453.13 573.68 453.13 582.75V585.78H391.66V582.75C391.66 573.68 399.02 566.32 408.09 566.32Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M485.789 566.32H514.399C523.469 566.32 530.829 573.68 530.829 582.75V585.78H469.359V582.75C469.359 573.68 476.719 566.32 485.789 566.32Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M174.059 566.32H202.669C211.739 566.32 219.099 573.68 219.099 582.75V585.78H157.629V582.75C157.629 573.68 164.989 566.32 174.059 566.32Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M567.971 503.629H632.101C644.301 503.629 654.201 513.529 654.201 525.729V585.779H545.871V525.729C545.871 513.529 555.771 503.629 567.971 503.629Z"
        fill="#7A6CFF"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M636.332 254.26V474.95C636.332 489.93 624.182 502.08 609.202 502.08H591.262V276.79C591.262 264.34 601.352 254.25 613.802 254.25H619.072"
        fill="white"
      />
      <path
        d="M636.332 254.26V474.95C636.332 489.93 624.182 502.08 609.202 502.08H591.262V276.79C591.262 264.34 601.352 254.25 613.802 254.25H619.072"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M619.059 254.262H652.069"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M672.068 381.202C672.068 393.652 661.978 403.742 649.528 403.742C637.078 403.742 626.988 393.652 626.988 381.202V276.802C626.988 264.352 637.078 254.262 649.528 254.262H689.398"
        fill="white"
      />
      <path
        d="M672.068 381.202C672.068 393.652 661.978 403.742 649.528 403.742C637.078 403.742 626.988 393.652 626.988 381.202V276.802C626.988 264.352 637.078 254.262 649.528 254.262H689.398"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M708.541 388.15C705.611 397.19 697.121 403.73 687.091 403.73C674.641 403.73 664.551 393.64 664.551 381.19V276.79C664.551 264.34 674.641 254.25 687.091 254.25H726.961"
        fill="white"
      />
      <path
        d="M708.541 388.15C705.611 397.19 697.121 403.73 687.091 403.73C674.641 403.73 664.551 393.64 664.551 381.19V276.79C664.551 264.34 674.641 254.25 687.091 254.25H726.961"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M748.34 303.57V359.11"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M748.34 366.859V350.239C748.34 337.789 758.43 327.699 770.88 327.699C783.33 327.699 793.42 337.789 793.42 350.239V370.049C793.42 388.659 778.33 403.739 759.73 403.739H748.35"
        fill="white"
      />
      <path
        d="M748.34 366.859V350.239C748.34 337.789 758.43 327.699 770.88 327.699C783.33 327.699 793.42 337.789 793.42 350.239V370.049C793.42 388.659 778.33 403.739 759.73 403.739H748.35"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M594.809 455.961H585.819C573.099 455.961 562.789 466.271 562.789 478.991C562.789 491.711 573.099 502.021 585.819 502.021H608.559"
        fill="white"
      />
      <path
        d="M594.809 455.961H585.819C573.099 455.961 562.789 466.271 562.789 478.991C562.789 491.711 573.099 502.021 585.819 502.021H608.559"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M748.34 403.74H736.52C718.15 403.74 703.27 388.85 703.27 370.49V276.81C703.27 264.36 713.36 254.27 725.81 254.27"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M167.062 432.039H213.503"
        stroke="#7A6CFF"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M262.293 432.039H441.853"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M174.773 475.07H221.223"
        stroke="#7A6CFF"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M269.812 475.07H449.372"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M151.09 344.32H197.14"
        stroke="#7A6CFF"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M253.602 344.32H427.352"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M159.781 389.02H206.221"
        stroke="#7A6CFF"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M253.602 389.02H434.952"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M131.922 384.922L148.222 480.072"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path d="M131.442 330.07L117.012 344.49L131.012 358.49" fill="white" />
      <path
        d="M131.442 330.07L117.012 344.49L131.012 358.49"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path d="M213.172 330.07L227.602 344.49L213.602 358.49" fill="white" />
      <path
        d="M213.172 330.07L227.602 344.49L213.602 358.49"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M567.371 299.391C567.371 299.391 582.261 298.121 588.391 272.891L591.251 300.151L567.371 299.391Z"
        fill="#383838"
      />
      <path
        d="M751.361 303.648C751.361 319.788 773.441 324.668 773.441 324.668L751.051 336.848L751.351 303.648H751.361Z"
        fill="#383838"
      />
      <path
        d="M494.339 127.15C500.43 127.15 505.369 122.212 505.369 116.12C505.369 110.028 500.43 105.09 494.339 105.09C488.247 105.09 483.309 110.028 483.309 116.12C483.309 122.212 488.247 127.15 494.339 127.15Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M483.201 173.8C486.321 173.8 488.851 171.27 488.851 168.15C488.851 165.03 486.321 162.5 483.201 162.5C480.08 162.5 477.551 165.03 477.551 168.15C477.551 171.27 480.08 173.8 483.201 173.8Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M874.05 549.11C871.74 558.66 877.6 568.27 887.15 570.59C896.7 572.91 906.31 567.04 908.63 557.49C910.94 547.94 905.08 538.33 895.53 536.01C885.98 533.69 876.37 539.56 874.05 549.11Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M930.791 479.301C936.772 479.301 941.621 474.452 941.621 468.471C941.621 462.489 936.772 457.641 930.791 457.641C924.81 457.641 919.961 462.489 919.961 468.471C919.961 474.452 924.81 479.301 930.791 479.301Z"
        fill="#D8D8D8"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M974.428 540.232C980.52 540.232 985.458 535.294 985.458 529.202C985.458 523.11 980.52 518.172 974.428 518.172C968.337 518.172 963.398 523.11 963.398 529.202C963.398 535.294 968.337 540.232 974.428 540.232Z"
        fill="white"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M681.64 487.519C681.64 487.519 678.16 404.549 758.81 406.589L744.09 403.739L716.18 398.399L708.6 391.789L696.93 404.569C696.93 404.569 684.07 404.439 683.05 403.739C682.03 403.039 679.84 407.199 679.84 407.199"
        fill="#383838"
      />
      <path
        d="M651.299 549.281C651.299 549.281 651.549 584.111 608.559 582.761V587.281L654.199 586.431L651.299 549.291V549.281Z"
        fill="#383838"
      />
    </g>
    <defs>
      <clipPath id="clip0_1164_780">
        <rect width="1008.86" height="624.52" fill="white" />
      </clipPath>
    </defs>
  </Box>
)

export default DevelopmentSVG
