import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.1102 69.73H21.1102C18.9362 69.7221 16.8535 68.8542 15.3171 67.3159C13.7808 65.7777 12.9154 63.6941 12.9102 61.52V20.3398"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M65.0415 69.7305H33.3516"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6 26.9604L12.4 20.5605C12.4707 20.4892 12.5548 20.4322 12.6475 20.3936C12.7401 20.3549 12.8396 20.335 12.9401 20.335C13.0405 20.335 13.1399 20.3549 13.2325 20.3936C13.3252 20.4322 13.4094 20.4892 13.4801 20.5605L19.88 26.9604"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.0898 20H68.4998C70.6729 20.0053 72.7556 20.8706 74.2922 22.4072C75.8289 23.9439 76.6945 26.0266 76.6998 28.1997V69.3896"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.6719 53.6499C24.6679 50.5806 25.373 47.5518 26.732 44.7998"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.6016 20H43.5715"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.3516 53.6504C33.3542 50.6419 34.5512 47.7577 36.6794 45.6313C38.8077 43.505 41.6931 42.3105 44.7015 42.3105C47.71 42.3105 50.5954 43.505 52.7236 45.6313C54.8519 47.7577 56.0489 50.6419 56.0515 53.6504"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.4216 41.64C33.2551 41.64 34.7416 40.1536 34.7416 38.32C34.7416 36.4864 33.2551 35 31.4216 35C29.588 35 28.1016 36.4864 28.1016 38.32C28.1016 40.1536 29.588 41.64 31.4216 41.64Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M83.6517 62.7705L77.2418 69.1704C77.172 69.2415 77.0887 69.2979 76.9968 69.3364C76.905 69.375 76.8064 69.3945 76.7068 69.3945C76.6072 69.3945 76.5086 69.375 76.4167 69.3364C76.3249 69.2979 76.2416 69.2415 76.1718 69.1704L69.7617 62.7705"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.5625 34.5801C40.5408 33.9476 42.6055 33.6269 44.6825 33.6299C49.9868 33.6299 55.0739 35.7371 58.8246 39.4878C62.5753 43.2385 64.6825 48.3256 64.6825 53.6299"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Box>
)

export default SVG
