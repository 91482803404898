// eslint-disable-next-line import/no-anonymous-default-export
export default {
  box: {
    // boxShadow: 'box',
    borderRadius: 'sm',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  panel: {
    // boxShadow: 'panel',
    borderRadius: {
      base: 'sm',
      md: 'lg'
    },
    backgroundColor: 'white',
    overflow: 'hidden',
    transition: 'background 100ms ease'
  }
}
