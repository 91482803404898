// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // body:{
  //   bg: 'red.500'
  // },
  brand: {
    100: '#7A6CFF',
    500: '#7A6CFF',
    600: '#6c5fe7',
    700: '#5243de'
  },
  blank: {
    100: '#7A6CFF',
    500: '#FFF',
    600: '#eaeaed',
    700: '#7A6CFF75'
  },
  gray: {
    light: '#FAFAFA',
    dark: '#FAFAFA',
    100: '#FAFAFA',
    200: '#EFEFEF',
    300: '#eaeaed',
    400: '#777B84'
  },
  illustration: {
    light: '#D8D8D8',
    dark: '#383838',
    accent: 'brand.100'
  },
  black: '#202024',
  // 'gray-top-30': 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(243,243,243,1) 30%, rgba(243,243,243,1) 100%)'
  'gray-top-30':
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(243,243,243,1) 70%, rgba(243,243,243,1) 100%)'
}
