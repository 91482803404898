import React from 'react'
import { Box } from '@chakra-ui/react'

// <svg width="521" height="468" viewBox="0 0 521 468" fill="none" xmlns="http://www.w3.org/2000/svg">

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 521 468"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1214_145)">
      <path
        d="M108.501 273.099C96.9012 303.199 88.3012 349.999 107.001 379.699C113.101 389.399 125.701 395.399 134.201 384.599C137.001 380.999 138.501 376.499 139.601 371.999C141.501 363.899 142.901 355.699 146.901 348.199C155.201 332.799 170.101 321.999 175.601 304.599C178.901 293.999 179.601 282.799 183.101 272.199"
        fill="#D8D8D8"
      />
      <path
        d="M108.501 273.099C96.9012 303.199 88.3012 349.999 107.001 379.699C113.101 389.399 125.701 395.399 134.201 384.599C137.001 380.999 138.501 376.499 139.601 371.999C141.501 363.899 142.901 355.699 146.901 348.199C155.201 332.799 170.101 321.999 175.601 304.599C178.901 293.999 179.601 282.799 183.101 272.199"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M89.6982 250.402C94.2982 264.902 100.998 278.802 109.498 291.802C118.198 305.102 128.698 317.202 140.498 327.802C171.698 355.802 211.598 373.002 252.898 379.102C295.198 385.402 339.098 380.502 379.198 365.902C390.598 361.802 401.698 356.802 412.298 351.102C448.098 331.902 480.798 303.502 498.298 266.202C500.198 262.102 501.798 257.902 502.898 253.502C503.498 250.802 503.998 248.002 503.598 245.302C502.698 238.602 497.698 235.702 491.498 238.102C481.998 241.802 473.698 247.102 463.698 249.702C452.898 252.602 441.498 253.302 430.498 250.702C404.998 244.702 388.998 228.902 381.098 204.002C375.998 187.902 375.498 170.902 371.798 154.602C368.698 141.102 365.198 127.702 359.898 114.902C344.998 78.7018 316.798 49.7018 278.598 38.9018C247.198 30.0018 213.098 33.3018 182.898 45.1018C143.898 60.4018 112.498 89.5018 95.7982 128.002C90.3982 140.502 86.4982 153.602 84.0982 167.002C79.1982 195.102 81.0982 223.702 89.6982 250.402Z"
        fill="#D8D8D8"
      />
      <path
        d="M89.6982 250.402C94.2982 264.902 100.998 278.802 109.498 291.802C118.198 305.102 128.698 317.202 140.498 327.802C171.698 355.802 211.598 373.002 252.898 379.102C295.198 385.402 339.098 380.502 379.198 365.902C390.598 361.802 401.698 356.802 412.298 351.102C448.098 331.902 480.798 303.502 498.298 266.202C500.198 262.102 501.798 257.902 502.898 253.502C503.498 250.802 503.998 248.002 503.598 245.302C502.698 238.602 497.698 235.702 491.498 238.102C481.998 241.802 473.698 247.102 463.698 249.702C452.898 252.602 441.498 253.302 430.498 250.702C404.998 244.702 388.998 228.902 381.098 204.002C375.998 187.902 375.498 170.902 371.798 154.602C368.698 141.102 365.198 127.702 359.898 114.902C344.998 78.7018 316.798 49.7018 278.598 38.9018C247.198 30.0018 213.098 33.3018 182.898 45.1018C143.898 60.4018 112.498 89.5018 95.7982 128.002C90.3982 140.502 86.4982 153.602 84.0982 167.002C79.1982 195.102 81.0982 223.702 89.6982 250.402Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M109.998 145.2C106.498 152 105.098 162.7 107.998 169.4C108.698 170.9 110.798 176.8 116.098 179.7C121.498 182.7 126.898 181.4 134.498 180.5C138.298 180 145.898 180.2 151.998 183.5C157.398 186.4 162.698 180 161.598 172.6C158.798 153.7 149.598 137.9 138.298 133.7C127.698 129.8 119.198 135.2 119.198 135.2C117.698 136.2 113.298 139 109.998 145.2Z"
        fill="white"
      />
      <path
        d="M109.998 145.2C106.498 152 105.098 162.7 107.998 169.4C108.698 170.9 110.798 176.8 116.098 179.7C121.498 182.7 126.898 181.4 134.498 180.5C138.298 180 145.898 180.2 151.998 183.5C157.398 186.4 162.698 180 161.598 172.6C158.798 153.7 149.598 137.9 138.298 133.7C127.698 129.8 119.198 135.2 119.198 135.2C117.698 136.2 113.298 139 109.998 145.2Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M274.001 146.701C278.501 154.801 279.301 167.201 274.201 174.601C273.101 176.301 269.201 182.701 261.101 185.401C252.801 188.201 245.101 186.201 233.901 184.301C228.301 183.401 217.101 182.801 207.801 185.801C199.501 188.501 192.401 180.601 194.801 172.301C201.001 151.001 216.301 134.001 233.401 130.401C249.501 127.101 261.301 134.301 261.301 134.301C263.701 135.501 269.801 139.201 274.001 146.701Z"
        fill="white"
      />
      <path
        d="M274.001 146.701C278.501 154.801 279.301 167.201 274.201 174.601C273.101 176.301 269.201 182.701 261.101 185.401C252.801 188.201 245.101 186.201 233.901 184.301C228.301 183.401 217.101 182.801 207.801 185.801C199.501 188.501 192.401 180.601 194.801 172.301C201.001 151.001 216.301 134.001 233.401 130.401C249.501 127.101 261.301 134.301 261.301 134.301C263.701 135.501 269.801 139.201 274.001 146.701Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M181.9 217.7L180.1 217.6L157.5 216.6L152.5 216.5C150.9 216.4 149.4 217.2 148.4 218.4C145.6 222.1 142.9 225.8 140.1 229.6C139.5 230.4 138.4 230.3 137.9 229.5L131.5 218.1C131.1 217.6 129.8 215.9 127.7 215.9C126.8 215.9 126.4 215.8 125.7 215.8L120.7 215.6L110.9 215.2C109.2 215.1 107.7 216.5 107.6 218.1C107.3 221.9 107.3 226.7 108.4 231.9C114 258.7 136.7 279.5 178.7 281.3"
        fill="white"
      />
      <path
        d="M181.9 217.7L180.1 217.6L157.5 216.6L152.5 216.5C150.9 216.4 149.4 217.2 148.4 218.4C145.6 222.1 142.9 225.8 140.1 229.6C139.5 230.4 138.4 230.3 137.9 229.5L131.5 218.1C131.1 217.6 129.8 215.9 127.7 215.9C126.8 215.9 126.4 215.8 125.7 215.8L120.7 215.6L110.9 215.2C109.2 215.1 107.7 216.5 107.6 218.1C107.3 221.9 107.3 226.7 108.4 231.9C114 258.7 136.7 279.5 178.7 281.3"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M178 281.102C220 282.902 244.4 264.002 252.2 237.802C253.7 232.702 254.2 227.902 254.2 224.102C254.2 222.402 252.8 221.002 251.1 220.902L241.3 220.502L236.3 220.302C235.6 220.302 235.1 220.302 234.3 220.202C232.2 220.102 230.9 221.602 230.4 222.102L223 233.002C222.5 233.802 221.3 233.802 220.8 233.002C218.4 229.102 215.9 225.102 213.5 221.202C212.7 219.802 211.2 219.002 209.6 218.902L204.6 218.602L182 217.702L180.2 217.602"
        fill="white"
      />
      <path
        d="M178 281.102C220 282.902 244.4 264.002 252.2 237.802C253.7 232.702 254.2 227.902 254.2 224.102C254.2 222.402 252.8 221.002 251.1 220.902L241.3 220.502L236.3 220.302C235.6 220.302 235.1 220.302 234.3 220.202C232.2 220.102 230.9 221.602 230.4 222.102L223 233.002C222.5 233.802 221.3 233.802 220.8 233.002C218.4 229.102 215.9 225.102 213.5 221.202C212.7 219.802 211.2 219.002 209.6 218.902L204.6 218.602L182 217.702L180.2 217.602"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M148 298.301C164.8 307.501 185.6 307.601 203.6 301.701"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M254.801 321.402C255.801 364.302 275.901 401.602 309.701 419.802C322.301 426.602 339.701 425.402 342.301 408.402C343.201 402.702 342.001 396.902 340.401 391.402C337.501 381.402 333.901 371.702 333.701 361.102C333.501 349.002 336.501 337.402 338.101 325.402C340.801 305.502 342.201 288.902 326.401 268.602"
        fill="#D8D8D8"
      />
      <path
        d="M254.801 321.402C255.801 364.302 275.901 401.602 309.701 419.802C322.301 426.602 339.701 425.402 342.301 408.402C343.201 402.702 342.001 396.902 340.401 391.402C337.501 381.402 333.901 371.702 333.701 361.102C333.501 349.002 336.501 337.402 338.101 325.402C340.801 305.502 342.201 288.902 326.401 268.602"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M256.002 352.302C255.602 355.302 269.902 383.302 228.602 371.402L243.202 378.902L271.202 381.402L257.902 350.102L256.002 352.302Z"
        fill="#383838"
      />
      <path
        d="M342.6 301C343.1 300.3 350.3 388.1 431.6 336.2L429.6 343.7L395.1 361.7L337.3 377.8L334.5 352.8L338.9 320.9L342.6 301Z"
        fill="#383838"
      />
      <path
        d="M119.5 309.5C119.5 309.5 143.5 345.3 134.8 377.4L138.7 371L150.8 339.3"
        fill="#383838"
      />
      <path
        d="M464.298 198.001H353.798L322.898 228.501C319.898 231.401 314.898 229.301 314.898 225.201V144.701C314.898 115.301 338.898 91.3008 368.298 91.3008H464.298C493.698 91.3008 517.698 115.301 517.698 144.701C517.698 174.001 493.598 198.001 464.298 198.001Z"
        fill="white"
      />
      <path
        d="M464.298 198.001H353.798L322.898 228.501C319.898 231.401 314.898 229.301 314.898 225.201V144.701C314.898 115.301 338.898 91.3008 368.298 91.3008H464.298C493.698 91.3008 517.698 115.301 517.698 144.701C517.698 174.001 493.598 198.001 464.298 198.001Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M469.598 145.702C469.598 151.002 465.298 155.302 459.998 155.302C454.698 155.302 450.398 151.002 450.398 145.702C450.398 140.402 454.698 136.102 459.998 136.102C465.298 136.102 469.598 140.402 469.598 145.702Z"
        fill="#D8D8D8"
      />
      <path
        d="M469.598 145.702C469.598 151.002 465.298 155.302 459.998 155.302C454.698 155.302 450.398 151.002 450.398 145.702C450.398 140.402 454.698 136.102 459.998 136.102C465.298 136.102 469.598 140.402 469.598 145.702Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M425.802 145.702C425.802 151.002 421.502 155.302 416.202 155.302C410.902 155.302 406.602 151.002 406.602 145.702C406.602 140.402 410.902 136.102 416.202 136.102C421.502 136.102 425.802 140.402 425.802 145.702Z"
        fill="#D8D8D8"
      />
      <path
        d="M425.802 145.702C425.802 151.002 421.502 155.302 416.202 155.302C410.902 155.302 406.602 151.002 406.602 145.702C406.602 140.402 410.902 136.102 416.202 136.102C421.502 136.102 425.802 140.402 425.802 145.702Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M382.098 145.702C382.098 151.002 377.798 155.302 372.498 155.302C367.198 155.302 362.898 151.002 362.898 145.702C362.898 140.402 367.198 136.102 372.498 136.102C377.798 136.102 382.098 140.402 382.098 145.702Z"
        fill="#D8D8D8"
      />
      <path
        d="M382.098 145.702C382.098 151.002 377.798 155.302 372.498 155.302C367.198 155.302 362.898 151.002 362.898 145.702C362.898 140.402 367.198 136.102 372.498 136.102C377.798 136.102 382.098 140.402 382.098 145.702Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M400.1 440.2C398.7 452.1 407.2 462.9 419.1 464.2C431 465.6 441.8 457.1 443.1 445.1C444.5 433.2 436 422.4 424.1 421.1C412.2 419.8 401.5 428.3 400.1 440.2Z"
        fill="#D8D8D8"
      />
      <path
        d="M400.1 440.2C398.7 452.1 407.2 462.9 419.1 464.2C431 465.6 441.8 457.1 443.1 445.1C444.5 433.2 436 422.4 424.1 421.1C412.2 419.8 401.5 428.3 400.1 440.2Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M3.10189 215.4C2.10189 224.1 8.30189 232 17.0019 233C25.7019 234 33.6019 227.8 34.6019 219.1C35.6019 210.4 29.4019 202.5 20.7019 201.5C12.0019 200.4 4.10189 206.7 3.10189 215.4Z"
        fill="white"
      />
      <path
        d="M3.10189 215.4C2.10189 224.1 8.30189 232 17.0019 233C25.7019 234 33.6019 227.8 34.6019 219.1C35.6019 210.4 29.4019 202.5 20.7019 201.5C12.0019 200.4 4.10189 206.7 3.10189 215.4Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M485.298 355.798C484.698 360.998 488.398 365.798 493.698 366.398C498.898 366.998 503.698 363.298 504.298 357.998C504.898 352.798 501.198 347.998 495.898 347.398C490.598 346.798 485.898 350.598 485.298 355.798Z"
        fill="white"
      />
      <path
        d="M485.298 355.798C484.698 360.998 488.398 365.798 493.698 366.398C498.898 366.998 503.698 363.298 504.298 357.998C504.898 352.798 501.198 347.998 495.898 347.398C490.598 346.798 485.898 350.598 485.298 355.798Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M368.4 11.3984C367.8 16.5984 371.5 21.3984 376.8 21.9984C382 22.5984 386.8 18.8984 387.4 13.5984C388 8.39835 384.3 3.59835 379 2.99835C373.7 2.49835 369 6.19835 368.4 11.3984Z"
        fill="white"
      />
      <path
        d="M368.4 11.3984C367.8 16.5984 371.5 21.3984 376.8 21.9984C382 22.5984 386.8 18.8984 387.4 13.5984C388 8.39835 384.3 3.59835 379 2.99835C373.7 2.49835 369 6.19835 368.4 11.3984Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M30.2981 308.198C29.6981 313.398 33.3981 318.198 38.6981 318.798C43.8981 319.398 48.6981 315.698 49.2981 310.398C49.8981 305.198 46.1981 300.398 40.8981 299.798C35.6981 299.198 30.9981 302.898 30.2981 308.198Z"
        fill="#D8D8D8"
      />
      <path
        d="M30.2981 308.198C29.6981 313.398 33.3981 318.198 38.6981 318.798C43.8981 319.398 48.6981 315.698 49.2981 310.398C49.8981 305.198 46.1981 300.398 40.8981 299.798C35.6981 299.198 30.9981 302.898 30.2981 308.198Z"
        stroke="#383838"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1214_145">
        <rect width="520.7" height="467.4" fill="white" />
      </clipPath>
    </defs>
  </Box>
)

export default SVG
