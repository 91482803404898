import { graphql, useStaticQuery } from 'gatsby'
import { useCallback, useContext } from 'react'
import { PageContext } from '../context'

const useServices = () => {
  const pageContext = useContext(PageContext)
  const { sanityDocuments } = useStaticQuery(
    graphql`
      query {
        sanityDocuments: allSanityCategory(filter: { active: { ne: false } }) {
          nodes {
            i18n_lang
            _id
            title
            path
            icon
          }
        }
      }
    `
  )
  const sanityDocumentsLocalized = sanityDocuments.nodes.filter(
    node => node.i18n_lang === pageContext.i18n_lang
  )
  const getDocument = useCallback(
    (field, value) =>
      sanityDocumentsLocalized.find(document => document[field] === value),
    [sanityDocumentsLocalized]
  )
  return [sanityDocuments, getDocument]
}

export default useServices
