import {
  Box,
  Container,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  useMediaQuery
} from '@chakra-ui/react'
import React from 'react'
import { useHomepage } from '../../gatsby-source-sanity'
import { motion } from 'framer-motion'
import { FadeUp } from '../UI/atoms'
import { IllustrationLoader } from '../illustrations'

const AdditionalServices = () => {
  const [isDesktop] = useMediaQuery(`(min-width: 768px)`) // md, or 62em for lg
  const { services_additional_title, services_additional } = useHomepage()
  return (
    <Container
      as={Flex}
      flexDirection={'column'}
      justifyContent={'center'}
      py={'2xl'}
    >
      <Heading size={'sm'} textAlign={'center'} mb={'2xl'}>
        {services_additional_title}
      </Heading>
      {services_additional && (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={'lg'}>
          {services_additional.map((service, index) => (
              <FadeUp
                multiplier={index * 0.2}
                key={service._id + '-additional'}
              >
                <LinkBox
                  as={motion.div}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  whileHover={'active'}
                  layerStyle={'box'}
                  py={'lg'}
                  px={'xl'}
                  bg={'gray.200'}
                  height={{ base: '27rem', md: '19rem' }}
                  transition={'background 100ms ease'}
                  sx={{
                    svg: { transition: 'color 100ms ease' }
                  }}
                  _hover={{
                    svg: { color: 'brand.500' },
                    bg: 'gray.100'
                  }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    width={'100%'}
                    justifyContent={{
                      base: 'space-evenly',
                      md: 'space-between'
                    }}
                    alignItems={{ base: 'center', md: 'stretch' }}
                    flexGrow={1}
                  >
                    <Heading as="h3" textStyle={'h4'}>
                      {isDesktop ? service.title : service.short_title}
                    </Heading>
                    <IllustrationLoader
                      svg={service.icon}
                      width={120}
                      height={120}
                      color={'illustration.dark'}
                    />
                    {/* @TODO: Use Flex properties in a way to avoid repeating the LinkOverlay */}
                    <LinkOverlay
                      href={service.path}
                      width={'100%'}
                      display={{ base: 'block', md: 'none' }}
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      <Heading color={'brand.600'} as={'span'} size="text">
                        {'See our projects →'}
                      </Heading>
                    </LinkOverlay>
                  </Flex>
                  <LinkOverlay
                    href={service.path}
                    width={'100%'}
                    display={{ base: 'none', md: 'block' }}
                    textAlign={{ base: 'center', md: 'left' }}
                  >
                    <Heading color={'brand.600'} as={'span'} size="text">
                      {'See our projects →'}
                    </Heading>
                  </LinkOverlay>
                </LinkBox>
              </FadeUp>
            ))}
        </SimpleGrid>
      )}
    </Container>
  )
}

export default AdditionalServices
