// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fonts: {
    heading: 'Manrope, sans-serif',
    body: 'Manrope, sans-serif',
    mono: 'Fira Code, monospace'
  },
  fontSizes: {
    xs: '1rem', // TBD
    sm: '1.375rem',
    md: '1.625rem',
    lg: '2.625rem',
    xl: '3.375rem',
    '2xl': '5rem',
    '3xl': '7rem'
  },
  lineHeights: {
    shorter: 1.14,
    short: 1.44,
    base: 1.64
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.02em',
    normal: '0',
    wide: '0.01em',
    wider: '0.04em'
  }
}
