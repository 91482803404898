import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.4734 20.0898H34.5234"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.4734 69.9102H34.5234"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.0898 56.6896V33.3096"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M69.9023 56.6896V33.3096"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.63 13H15.55C14.1417 13 13 14.1417 13 15.55V24.63C13 26.0383 14.1417 27.18 15.55 27.18H24.63C26.0383 27.18 27.18 26.0383 27.18 24.63V15.55C27.18 14.1417 26.0383 13 24.63 13Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M74.4425 13H65.3625C63.9542 13 62.8125 14.1417 62.8125 15.55V24.63C62.8125 26.0383 63.9542 27.18 65.3625 27.18H74.4425C75.8508 27.18 76.9925 26.0383 76.9925 24.63V15.55C76.9925 14.1417 75.8508 13 74.4425 13Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.63 62.8193H15.55C14.1417 62.8193 13 63.961 13 65.3693V74.4493C13 75.8577 14.1417 76.9993 15.55 76.9993H24.63C26.0383 76.9993 27.18 75.8577 27.18 74.4493V65.3693C27.18 63.961 26.0383 62.8193 24.63 62.8193Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M74.4425 62.8193H65.3625C63.9542 62.8193 62.8125 63.961 62.8125 65.3693V74.4493C62.8125 75.8577 63.9542 76.9993 65.3625 76.9993H74.4425C75.8508 76.9993 76.9925 75.8577 76.9925 74.4493V65.3693C76.9925 63.961 75.8508 62.8193 74.4425 62.8193Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.2192 43.9282L36.4076 35.7399C38.6918 33.4557 41.7898 32.1724 45.0201 32.1724C48.2504 32.1724 51.3486 33.4559 53.6328 35.74L61.8208 43.9281C62.0665 44.1737 62.2048 44.5072 62.2048 44.8547C62.2048 45.2021 62.0665 45.535 61.8209 45.7807L53.6325 53.969C52.5015 55.1 51.1591 55.9974 49.6814 56.6095C48.2036 57.2216 46.6195 57.5365 45.02 57.5365C43.4205 57.5365 41.8369 57.2216 40.3592 56.6095C38.8814 55.9974 37.5387 55.1002 36.4077 53.9692L28.2193 45.7808C27.9736 45.5352 27.8356 45.202 27.8356 44.8546C27.8356 44.5071 27.9736 44.1739 28.2192 43.9282V43.9282Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.0617 49.3793C47.5746 49.3793 49.6117 47.3422 49.6117 44.8293C49.6117 42.3164 47.5746 40.2793 45.0617 40.2793C42.5488 40.2793 40.5117 42.3164 40.5117 44.8293C40.5117 47.3422 42.5488 49.3793 45.0617 49.3793Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Box>
)

export default SVG
