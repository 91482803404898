import { defineStyleConfig } from '@chakra-ui/react'
// eslint-disable-next-line import/no-anonymous-default-export
export default defineStyleConfig({
  baseStyle: {
    fontSize: 'sm',
    ml: 'xl',
    span: {
      display: 'none'
    }
  }
})
