import { Box, Button, Container, Flex, Link } from '@chakra-ui/react'
import React from 'react'
import { Logo } from '../components/UI/atoms'

const Header = () => (
  <Container as={'header'} bg={'white'} position={'relative'} h={0} zIndex={10}>
    <Flex
      pt={{ base: 'md', lg: 'xl' }}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Link href={'/'} aria-label={"Navigate to SpiritDigital's homepage"}>
        <Logo w={{ base: '10rem', sm: '12.5rem' }} />
      </Link>
      <Button
        as={Link}
        href={'#contact'}
        px={{ base: 'md', sm: '2.5rem' }}
        onClick={e => {
          e.preventDefault()
          document
            .querySelector('#contact')
            .scrollIntoView({ behavior: 'smooth' })
        }}
        whiteSpace={'nowrap'}
        colorScheme={'brand'}
      >
        Talk to us
      </Button>
    </Flex>
  </Container>
)

export default Header
