import React from 'react'
import { motion } from 'framer-motion'

const variants = {
  offscreen: {
    y: '50%', // not working as expected with overflow hidden parents and while in view
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1
  }
}

const transition = {
  type: 'spring',
  bounce: 0.4,
  duration: 0.8
}

const FadeUp = ({ delay = 0.3, multiplier = 1, ...props }) => {
  return (
    <motion.div
      // ref={ref}
      // animate={controls}
      initial={'offscreen'}
      whileInView={'onscreen'}
      variants={variants}
      viewport={{ once: true, amount: 0.15 }}
      transition={{
        ...transition,
        delay: delay * multiplier
      }}
      {...props}
    />
  )
}

export default FadeUp
