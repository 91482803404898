import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.3832 60.0591L50.4631 68.1094C48.9186 69.8931 46.7317 70.9939 44.3789 71.1719C42.0261 71.3498 39.6984 70.5902 37.9032 69.0591L10.2031 44.9795"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.7432 55.3794L10.0032 45.5791C9.9936 45.4706 10.0058 45.3616 10.0389 45.2578C10.0721 45.154 10.1256 45.0575 10.1964 44.9746C10.2672 44.8917 10.3537 44.8239 10.451 44.7749C10.5484 44.7259 10.6545 44.697 10.7632 44.6895L20.5632 43.9492"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.0938 52.9492L36.9238 44.9991C38.4683 43.2144 40.6564 42.1142 43.0099 41.938C45.3634 41.7619 47.6909 42.5243 49.4838 44.0591L79.4838 69.4492"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.9333 59.0508L79.6733 68.8506C79.6873 69.0686 79.6156 69.2835 79.4738 69.4497C79.3319 69.6159 79.1309 69.7204 78.9133 69.7407L69.1133 70.4707"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.5133 34.3C49.2905 34.3 53.1633 30.4273 53.1633 25.65C53.1633 20.8727 49.2905 17 44.5133 17C39.736 17 35.8633 20.8727 35.8633 25.65C35.8633 30.4273 39.736 34.3 44.5133 34.3Z"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.0842 64.0391L13.0742 72.8691"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M73.4436 41.8799L65.4336 50.6999"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Box>
)

export default SVG
