import colors from './colors'
import typography from './typography'
import textStyles from './textStyles'
import layerStyles from './layerStyles'
import radii from './radii'
import shadows from './shadows'
import space from './space'
import breakpoints from './breakpoints'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors,
  ...typography,
  textStyles,
  layerStyles,
  radii,
  shadows,
  space,
  breakpoints
}
