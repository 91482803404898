import { useSeo } from './index'

const useOrganizationSchema = () => {
  const {
    title,
    logo,
    addressPoints,
    contactPoints,
    profiles,
    foundingDate,
    legalName,
    taxID,
    vatID
  } = useSeo()
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    ...(title ? { name: title } : {}),
    url: 'https://www.mycompany.com',
    ...(!!logo?.asset.url ? { logo: logo.asset.url } : {}),
    ...(!!addressPoints?.length
      ? {
          contactPoint: addressPoints.map(point => ({
            '@type': 'PostalAddress',
            streetAddress: point.street,
            addressLocality: point.city,
            addressRegion: point.region,
            postalCode: point.postalCode,
            addressCountry: point.country
          }))
        }
      : {}),
    ...(!!contactPoints?.length
      ? {
          contactPoint: contactPoints.map(point => ({
            '@type': 'ContactPoint',
            telephone: point.phone,
            contactType: point.contactType,
            email: point.email,
            areaServed: point.areaServed,
            availableLanguage: point.availableLanguage
          }))
        }
      : {}),
    ...(!!profiles?.length ? { sameAs: profiles } : {}),
    ...(foundingDate ? { foundingDate: foundingDate } : {}),
    ...(legalName ? { legalName: legalName } : {}),
    ...(taxID ? { taxID: taxID } : {}),
    ...(vatID ? { vatID: vatID } : {})
  }
}

export default useOrganizationSchema
