import React from 'react'
import { Box } from '@chakra-ui/react'

const ContactSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 702 609"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M256.379 86.9219H496.359C561.209 86.9219 613.849 139.572 613.849 204.412V344.932C613.849 349.602 610.059 353.392 605.389 353.392H256.379V86.9219Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M373.869 204.42V353.41H138.879V204.42C138.879 139.53 191.479 86.9297 256.369 86.9297C264.459 86.9297 272.349 87.7497 279.979 89.3097C302.959 93.9897 323.489 105.38 339.449 121.34C356.609 138.49 368.479 160.91 372.429 185.97C373.379 191.98 373.869 198.14 373.869 204.42Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M373.868 205.059V353.409H157.918V205.059C157.918 172.499 173.278 143.049 198.068 121.839C235.568 89.7692 296.238 89.7792 333.718 121.859C333.898 122.019 334.088 122.179 334.268 122.339C354.008 139.419 367.658 161.739 372.198 186.689C373.288 192.669 373.858 198.809 373.858 205.059H373.868Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M270.627 263.25H373.868V327.86H270.627C268.127 327.86 266.098 325.83 266.098 323.33V267.78C266.098 265.28 268.127 263.25 270.627 263.25Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M373.868 236.871H254.777V250.061V263.251H373.868V236.871Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M373.868 210.48H272.488V236.86H373.868V210.48Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.72"
      d="M372.218 186.691C367.678 161.741 354.018 139.411 334.288 122.341C334.108 122.181 333.918 122.021 333.738 121.861C308.358 100.141 272.348 93.1412 240.098 100.831C255.488 104.501 270.028 111.501 282.138 121.861C282.318 122.021 282.507 122.181 282.688 122.341C302.427 139.421 316.078 161.741 320.618 186.691C321.708 192.671 322.277 198.811 322.277 205.061V353.411H373.868V205.061C373.868 198.811 373.308 192.671 372.208 186.691H372.218Z"
      fill="#383838"
    />
    <path
      d="M322.276 205.061V209.281H373.866V205.061C373.866 198.811 373.306 192.671 372.206 186.691C367.666 161.741 354.006 139.411 334.276 122.341C334.096 122.181 333.906 122.021 333.726 121.861C308.346 100.141 272.336 93.1412 240.086 100.831C255.476 104.501 270.016 111.501 282.126 121.861C282.306 122.021 282.496 122.181 282.676 122.341C302.416 139.421 316.066 161.741 320.606 186.691C321.696 192.671 322.266 198.811 322.266 205.061H322.276Z"
      fill="#383838"
    />
    <path d="M373.868 341.66H322.277V353.4H373.868V341.66Z" fill="#383838" />
    <path
      d="M449.347 353.398H373.867V604.748H449.347V353.398Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M464.447 353.398H431.457V604.748H464.447V353.398Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M435.12 3.25H468.109V187.33C468.109 196.43 460.719 203.83 451.609 203.83C442.509 203.83 435.109 196.44 435.109 187.33V3.25H435.12Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M525.207 45.9204V3.25L436.097 3.25V45.9204L525.207 45.9204Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M521.137 530.9C519.327 546.54 530.537 560.69 546.177 562.51C561.817 564.32 575.967 553.11 577.787 537.47C579.597 521.83 568.387 507.68 552.747 505.86C537.107 504.05 522.957 515.26 521.137 530.9Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M608.128 474.33C616.893 474.33 623.998 467.225 623.998 458.46C623.998 449.695 616.893 442.59 608.128 442.59C599.363 442.59 592.258 449.695 592.258 458.46C592.258 467.225 599.363 474.33 608.128 474.33Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M530.489 414.529C535.758 414.529 540.029 410.258 540.029 404.989C540.029 399.72 535.758 395.449 530.489 395.449C525.22 395.449 520.949 399.72 520.949 404.989C520.949 410.258 525.22 414.529 530.489 414.529Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M95.1367 516.629C106.133 516.629 115.046 507.715 115.046 496.719C115.046 485.723 106.133 476.809 95.1367 476.809C84.1407 476.809 75.2266 485.723 75.2266 496.719C75.2266 507.715 84.1407 516.629 95.1367 516.629Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M327.06 563.081C332.064 563.081 336.12 559.025 336.12 554.021C336.12 549.017 332.064 544.961 327.06 544.961C322.056 544.961 318 549.017 318 554.021C318 559.025 322.056 563.081 327.06 563.081Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M38.8281 605.172H701.178"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M97.0566 325.561H373.876V353.401H114.097C103.637 353.401 95.1367 344.911 95.1367 334.441V327.471C95.1367 326.411 95.9966 325.551 97.0566 325.551V325.561Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M376.448 455.488C377.048 375.488 466.058 364.168 466.058 364.168V350.648L375.488 352.448L376.448 455.488Z"
      fill="#383838"
    />
    <path
      d="M327.055 285.48C350.005 285.48 368.825 303.1 370.755 325.54H215.625L267.945 285.48H327.055Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M327.054 285.48H201.414C177.394 285.48 157.914 304.96 157.914 328.98V604.56H243.614C265.674 604.56 283.554 586.68 283.554 564.62V328.98C283.554 304.96 303.034 285.48 327.054 285.48Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M133.036 604.56H246.466C223.516 604.56 204.696 586.94 202.766 564.5H89.3359C91.2759 586.95 110.096 604.56 133.036 604.56Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 408.199H200.928"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M213.898 408.199H264.019"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 510.59H224.278"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M237.949 510.59H262.309"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M210.34 538.141H250.21"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 538.141H200.928"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 380.66H232.408"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M242.5 380.66H264.02"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 325.559H218.078"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 455.488H227.758"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 432.941H264.018"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 353.109H250.208"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.578 483.039H250.208"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M431.951 146.759C431.951 146.759 425.231 98.6994 382.141 89.9194L434.28 88.1094L431.96 146.759H431.951Z"
      fill="#383838"
    />
    <path
      d="M59.0386 217.928H173.858L206.008 249.598C209.088 252.628 214.298 250.448 214.298 246.128V162.458C214.298 131.948 189.339 106.988 158.829 106.988H59.0283C28.5183 106.988 3.55859 131.948 3.55859 162.458C3.55859 192.968 28.5183 217.928 59.0283 217.928H59.0386Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M69.0293 170.2C73.917 170.2 77.8794 166.238 77.8794 161.35C77.8794 156.462 73.917 152.5 69.0293 152.5C64.1416 152.5 60.1797 156.462 60.1797 161.35C60.1797 166.238 64.1416 170.2 69.0293 170.2Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M109.538 170.2C114.425 170.2 118.388 166.238 118.388 161.35C118.388 156.462 114.425 152.5 109.538 152.5C104.65 152.5 100.688 156.462 100.688 161.35C100.688 166.238 104.65 170.2 109.538 170.2Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M150.038 170.2C154.925 170.2 158.888 166.238 158.888 161.35C158.888 156.462 154.925 152.5 150.038 152.5C145.15 152.5 141.188 156.462 141.188 161.35C141.188 166.238 145.15 170.2 150.038 170.2Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M318.209 210.48H261.18"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M318.209 236.871H244.789"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M318.209 263.25H244.789"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M465.12 73.4286C464.52 52.9286 438.16 48.9486 438.16 48.9486L470.06 46.5586L468.11 73.8386L465.12 73.4286Z"
      fill="#383838"
    />
  </Box>
)

export default ContactSVG
