import { AspectRatio, Badge, Box, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { SanityImage } from '../../gatsby-source-sanity'

const ProjectCard = ({
  _type,
  isBigClient,
  featuredImage,
  title,
                       score,
  website,
  path
}) => {
  const imgAspect = featuredImage?.asset.width / featuredImage?.asset.height
  return (
    <LinkBox>
      <AspectRatio ratio={1} layerStyle={'box'}>
        <Box w={'full'} h={'full'}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              position: 'absolute',
            }}
            top={{base:'sm', md: 'lg', lg: 'xl'}}
            left={{base:'sm', md: 'lg', lg: 'xl'}}
            right={{base:'sm', md: 'lg', lg: 'xl'}}
          >
            {isBigClient && <Badge variant={'brand'} fontSize={{base:'xs', md: 'sm', lg: 'md'}}
                                   px={{base:'md', sm:'lg', md: 'xl'}}>Featured</Badge>}
          </Flex>
          {featuredImage && (
            <SanityImage
              image={featuredImage}
              // mx={'auto'}
              // w={8 / 10}
              w={imgAspect < 1.44 && imgAspect * 230}
              mx={'auto'}
              maxW={{ base: 140, md: 230, lg: 450 }}
              maxH={{ base: 140, sm: 160, md: 200, lg: 230 }}
            />
          )}
        </Box>
      </AspectRatio>
      <Flex alignItems={'center'} justifyContent={'space-between'} pt={'lg'}>
        <Text as={LinkOverlay} href={website} isExternal textStyle={'h5-xs'}>
          {title}
        </Text>
        <Badge display={{base:'none', lg:'inline-block'}} variant={'outline'} fontSize={{base:'xs', md: 'sm'}}
               px={{base:'md', sm:'lg', md: 'xl'}}>
          {_type.charAt(0).toUpperCase() + _type.slice(1)}
        </Badge>
      </Flex>
    </LinkBox>
  )
}

export default ProjectCard

export const fragment = graphql`
  fragment ProjectCard on SanityProject {
    _id
    title
    slug {
      current
    }
    logo {
      _key
      _type
      _rawAsset
      _rawHotspot
      _rawCrop
      asset {
        _id
        gatsbyImageData
        width
        height
      }
    }
  }
`
