import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1206_249)">
      <path
        d="M28.1406 41.7431L38.5406 30.9633C40.2972 29.1289 42.7085 28.0647 45.2476 28.0029C47.7866 27.941 50.2468 28.8865 52.0906 30.6332L82.9706 59.5834"
        stroke="#7A6CFF"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M82.9595 48.3633L83.2195 58.9531C83.2222 59.1884 83.1327 59.4158 82.9701 59.5859C82.8075 59.756 82.5847 59.8555 82.3495 59.8633L71.7695 60.1133"
        stroke="#7A6CFF"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M78.0109 31.6133L69.9609 39.6433"
        stroke="#383838"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M63.0522 47.9131L51.6823 59.6729C49.9213 61.5031 47.5092 62.5642 44.9701 62.626C42.431 62.6878 39.9701 61.7453 38.1222 60.0029L7.24219 31.0029"
        stroke="#383838"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.25052 42.2627L7.00052 31.6729C6.99652 31.5565 7.01561 31.4405 7.05679 31.3315C7.09798 31.2226 7.16039 31.1227 7.24039 31.0381C7.32038 30.9534 7.41641 30.8856 7.52286 30.8384C7.6293 30.7911 7.74407 30.7653 7.86051 30.7627L18.4405 30.5127"
        stroke="#383838"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.4023 60.3636L19.6123 51.1836"
        stroke="#7A6CFF"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1206_249"
        x="1.5"
        y="26.5"
        width="87.2188"
        height="45.6289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1206_249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1206_249"
          result="shape"
        />
      </filter>
    </defs>
  </Box>
)

export default SVG
