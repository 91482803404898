import { Flex, Heading, Link, Stack, useBreakpointValue } from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { wrap } from '@motionone/utils'
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity
} from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { SanityImage, useHomepage } from '../../gatsby-source-sanity'

const useWindowWidth = () => {
  const isBrowser = typeof window !== 'undefined'
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)

  useEffect(() => {
    if (!isBrowser) {
      return false
    }

    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return width
}

function ParallaxText({ children, direction = 1 }) {
  const windowWidth = useWindowWidth()
  const elementRef = useRef()
  const sectionSize = useSize(elementRef)
  const baseX = useMotionValue(0)
  const { scrollY } = useScroll()
  const scrollVelocity = useVelocity(scrollY)
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  })
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  })

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   *
   * mporw na kolsiw 2 sections mazi kai na upologisw poses fores megalitero einai to prwto apo to viewport
   * kai meta otan ftasi i arxi tou deuterou stin akri tis othonis na kanw reset
   */
  const sectionWidth = sectionSize?.width ? sectionSize.width / 2 : windowWidth
  const x = useTransform(baseX, dt => `${wrap(0, -sectionWidth, dt)}px`)
  const directionFactor = useRef(1)
  const speed = useBreakpointValue({ base: 3, sm: 5, md: 8, lg: 10 })
  useAnimationFrame((t, delta) => {
    // distance to move
    const distanceTraveled = windowWidth / speed
    // time it takes from start to end of distance
    const time = delta / 1000
    // delta / 1000 = 1s
    let moveBy = directionFactor.current * distanceTraveled * time * direction
    // switch direction
    if (velocityFactor.get()) {
      directionFactor.current = velocityFactor.get() < 0 ? -1 : 1
    }
    moveBy += directionFactor.current * moveBy * velocityFactor.get()
    baseX.set(baseX.get() + moveBy)
  })

  return (
    <Flex overflow={'hidden'} maxW={{base:'100vw', md: '98.9vw'}} className='parallax' pb={'xs'}>
      <Flex
        as={motion.div}
        alignItems={'center'}
        flexWrap={'nowrap'}
        ref={elementRef}
        style={{ x }}
      >
        {children}
        {children}
      </Flex>
    </Flex>
  )
}

const Logos = ({ trusted_by }) => {
  return (
    <React.Fragment>
      {trusted_by &&
        trusted_by.map((trusted, index) => {
          const ar = trusted?.logo?.asset?.width / trusted?.logo?.asset?.height
          return (
            <Flex
              as={Link}
              alignItems={'center'}
              // display={'block'}
              href={trusted.website}
              isExternal
              p={'lg'}
              mx={'sm'}
              key={trusted.logo.asset._id}
              bg={'white'}
              borderRadius={'sm'}
              aria-label={trusted.title + ' logo'}
            >
              <SanityImage
                image={trusted.logo}
                // height={100}
                width={ar < 1.44 ? 100 : (ar > 1.6 ? 200 : 130)}
              />
            </Flex>
          )
        })}
    </React.Fragment>
  )
}

const TrustedBy = () => {
  const { trusted_title, trusted_by } = useHomepage()
  const { allSanityClient } = useStaticQuery(
    graphql`
      query {
        allSanityClient(filter: { active: { eq: true } }) {
          nodes {
            logo {
              asset {
                _id
                width
                height
              }
            }
            title
            website
          }
        }
      }
    `
  )
  // const half = Math.ceil(trusted_by.length / 2)
  return (
    <Stack justifyContent={'center'} maxW={'full'} bg={'white'} py={190}>
      <Heading size={'sm'} textAlign={'center'} mb={'2xl'}>
        {trusted_title}
      </Heading>
      <ParallaxText>
        <Logos trusted_by={trusted_by} />
      </ParallaxText>
      <ParallaxText direction={-1}>
        <Logos trusted_by={allSanityClient.nodes} />
      </ParallaxText>
    </Stack>
  )
}

export default TrustedBy
