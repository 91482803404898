import container from './container'
import text from './text'
import heading from './heading'
import button from './button'
import formLabel from './form-label'
import input from './input'
import textarea from './textarea'
import link from './link'
import badge from './badge'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Container: container,
  Text: text,
  Heading: heading,
  Button: button,
  FormLabel: formLabel,
  Input: input,
  Textarea: textarea,
  Badge: badge,
  Link: link
}
