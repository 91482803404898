// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: {
    html: {
      fontSize: '0.875rem',
      '@media screen and (min-width: 400px)': {
        fontSize: 'clamp(0.875rem, 0.223vw + 0.8rem, 1rem)' // 14 @ 400 - 16 @ 1440
      },
      '@media screen and (min-width: 1440px)': {
        fontSize: 'clamp(1rem, 0.195vw + 0.821rem, 1.125rem)' // 16 @ 1440 - 18 @ 2560
      },
      // '@media screen and (min-width: 1440px)': {
      // 	fontSize: 'clamp(1rem, 0.534vw + 0.521rem, 1.375rem)' // 16 @ 1440 - 22 @ 2560
      // },
      // '@media screen and (min-width: 2560px)': {
      // 	fontSize: 'clamp(1.375rem, 0.435vw + 0.68rem, 1.75rem)' // 22 @ 2500 - 28 @ 3840
      // },
      '@media screen and (min-width: 3840px)': {
        fontSize: 'clamp(1.75rem, 0.9vw - 0.2rem, 2.125rem)' // 28 @ 3840 - 34 @ ~
      }
      // fontSize: '0.875rem',
      // '@media screen and (min-width: 400px)': {
      //   fontSize: 'clamp(0.875rem, 0.223vw + 0.8rem, 1rem)' // 14 @ 400 - 16 @ 1440
      // },
      // '@media screen and (min-width: 1440px)': {
      //   fontSize: 'clamp(1rem, 0.195vw + 0.821rem, 1.125rem)' // 16 @ 1440 - 18 @ 2560
      // },
      // // '@media screen and (min-width: 1440px)': {
      // // 	fontSize: 'clamp(1rem, 0.534vw + 0.521rem, 1.375rem)' // 16 @ 1440 - 22 @ 2560
      // // },
      // // '@media screen and (min-width: 2560px)': {
      // // 	fontSize: 'clamp(1.375rem, 0.435vw + 0.68rem, 1.75rem)' // 22 @ 2500 - 28 @ 3840
      // // },
      // '@media screen and (min-width: 3840px)': {
      //   fontSize: 'clamp(1.75rem, 0.9vw - 0.2rem, 2.125rem)' // 28 @ 3840 - 34 @ ~
      // }
    },
    body: {
      bg: 'white',
      color: 'black',
      fontSize: 'sm'
    },
    a: {
      cursor: 'pointer'
      // color: 'brand.500',
      // _hover: {
      // 	textDecoration: 'underline'
      // }
    }
  }
}
