// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle: {
    maxW: {
      md: '80rem',
      lg: '90rem', // 1200px
      // full: '80rem'
    },
    px: {
      base: 'lg',
      sm: 'xl',
      md: '2xl',
      lg: '3xl' // 112px
    }
  },
  variants: {
    'gutters-lg': {
      px: {
        base: 'lg',
        sm: 'lg',
        md: 'lg',
        lg: 'lg',
      }
    }
  },
  defaultProps: {}
}
