import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.9701 69.1997H15.79C13.7247 69.1918 11.7463 68.3671 10.2869 66.9058C8.82738 65.4444 8.00527 63.4652 8 61.3999V19.7998C8.00527 17.7345 8.82738 15.7548 10.2869 14.2935C11.7463 12.8321 13.7247 12.0079 15.79 12H64.15C66.2154 12.0079 68.1937 12.8321 69.6532 14.2935C71.1127 15.7548 71.9348 17.7345 71.9401 19.7998V33.5796"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.8086 22.5693H35.8786"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.8086 32.5693H59.5386"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M72.1785 42.0293H53.1785C52.9637 42.0287 52.7526 42.0848 52.5665 42.1919C52.3804 42.299 52.2258 42.4532 52.1184 42.6392L42.6184 59.0894C42.5151 59.2761 42.4609 59.4858 42.4609 59.6992C42.4609 59.9126 42.5151 60.1224 42.6184 60.3091L52.1184 76.7593C52.2273 76.9439 52.3822 77.0972 52.568 77.2041C52.7538 77.311 52.9641 77.3678 53.1785 77.3691H72.1785C72.3928 77.3678 72.6032 77.311 72.7889 77.2041C72.9747 77.0972 73.1295 76.9439 73.2384 76.7593L82.7384 60.3091C82.8417 60.1224 82.896 59.9126 82.896 59.6992C82.896 59.4858 82.8417 59.2761 82.7384 59.0894L73.2384 42.6392C73.131 42.4532 72.9765 42.299 72.7904 42.1919C72.6043 42.0848 72.3932 42.0287 72.1785 42.0293V42.0293Z"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62.6794 67.0199C66.7221 67.0199 69.9994 63.7426 69.9994 59.6999C69.9994 55.6572 66.7221 52.3799 62.6794 52.3799C58.6367 52.3799 55.3594 55.6572 55.3594 59.6999C55.3594 63.7426 58.6367 67.0199 62.6794 67.0199Z"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.5685 59.5093H18.9685C18.73 59.5106 18.4935 59.4649 18.2727 59.3745C18.052 59.2842 17.8513 59.1511 17.6821 58.9829C17.513 58.8147 17.3788 58.6143 17.2872 58.3941C17.1956 58.1738 17.1484 57.9378 17.1484 57.6992V43.8394C17.1484 43.6008 17.1956 43.3648 17.2872 43.1446C17.3788 42.9243 17.513 42.7239 17.6821 42.5557C17.8513 42.3875 18.052 42.2545 18.2727 42.1641C18.4935 42.0737 18.73 42.028 18.9685 42.0293H42.8385"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Box>
)

export default SVG
