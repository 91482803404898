import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.9498 25.4704C62.3577 27.2692 63.5336 29.2384 64.4498 31.3308C65.8604 34.5626 66.6205 38.0405 66.6869 41.5661C66.7532 45.0917 66.1245 48.5955 64.8365 51.8781C63.5486 55.1607 61.6266 58.1577 59.1804 60.6975C56.7342 63.2373 53.8118 65.2701 50.5798 66.6804C47.3487 68.0908 43.8714 68.851 40.3465 68.9172C36.8217 68.9834 33.3183 68.3546 30.0365 67.0666C26.7547 65.7786 23.7587 63.8565 21.2198 61.4104C18.681 58.9643 16.6491 56.0424 15.2399 52.8108C12.3936 46.2862 12.2546 38.8982 14.8536 32.2712C17.4527 25.6443 22.5768 20.3205 29.0997 17.4704C33.2128 15.6644 37.7151 14.925 42.1898 15.3205"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.5064 38.1205C30.5064 36.2703 29.0088 34.7705 27.1614 34.7705C25.314 34.7705 23.8164 36.2703 23.8164 38.1205V51.6405C23.8164 53.4907 25.314 54.9905 27.1614 54.9905C29.0088 54.9905 30.5064 53.4907 30.5064 51.6405V38.1205Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M76.007 78.4401C75.0908 79.3752 73.8444 79.9134 72.5355 79.9396C71.2267 79.9658 69.9598 79.4779 69.007 78.5803L62.907 72.6999C61.9719 71.7837 61.4334 70.5375 61.4073 69.2287C61.3811 67.9198 61.8693 66.6527 62.767 65.6999V65.6999C63.6819 64.7634 64.9275 64.2235 66.2364 64.1955C67.5452 64.1674 68.8129 64.6536 69.767 65.55L75.867 71.4401C76.802 72.3563 77.3405 73.6025 77.3667 74.9113C77.3929 76.2201 76.9047 77.4873 76.007 78.4401V78.4401Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.8189 29.1703C42.8189 27.3202 41.3213 25.8203 39.4739 25.8203C37.6265 25.8203 36.1289 27.3202 36.1289 29.1703V51.6503C36.1289 53.5005 37.6265 55.0003 39.4739 55.0003C41.3213 55.0003 42.8189 53.5005 42.8189 51.6503V29.1703Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M55.4283 14.35C55.4283 12.4999 53.9307 11 52.0833 11C50.2359 11 48.7383 12.4999 48.7383 14.35V51.64C48.7383 53.4902 50.2359 54.99 52.0833 54.99C53.9307 54.99 55.4283 53.4902 55.4283 51.64V14.35Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </Box>
)

export default SVG
