import {
  Button,
  Container,
  Link,
  Text,
  SimpleGrid,
  Box,
  Stack
} from '@chakra-ui/react'
import React from 'react'
import { Logo } from '../components/UI/atoms'
import { useSettings } from '../gatsby-source-sanity'
import { ContactForm } from '../components/sections'
import { NormanMumbling } from '../components/illustrations/logo'

const FooterLink = props => (
  <Button
    as={Link}
    variant={'tab'}
    {...props}
    bg={'gray.200'}
    size={'lg'}
    fontWeight={'bold'}
  />
)

const FooterLinks = props => {
  const { social, email, phone, maps } = useSettings()
  return (
    <Box
      pt={'xl'}
      pb={'3xl'}
      borderTop={'2px solid transparent'}
      borderBottom={'2px solid transparent'}
      borderColor={'#D8D8D8'}
    >
      <Text textStyle={'text-short'} textAlign={'center'} py={'lg'}>
        We are here for you
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={'md'}>
        {email && <FooterLink href={`mailto:${email}`}>{email}</FooterLink>}
        {phone && (
          <FooterLink href={`tel:${phone.replaceAll(' ', '')}`}>
            {phone}
          </FooterLink>
        )}
        {maps && (
          <FooterLink href={maps} isExternal>
            Maps
          </FooterLink>
        )}
        {social &&
          social.map(item => (
            <FooterLink
              key={item.platform + '-footer'}
              href={item.url}
              textTransform={'capitalize'}
              isExternal
            >
              {item.platform}
            </FooterLink>
          ))}
      </SimpleGrid>
    </Box>
  )
}

const Owen = props => (
  <Link
    href={`https://www.owenhoskins.com/`}
    rel={'nofollow noopener'}
    isExternal
    {...props}
  >
    Owen Hoskins
  </Link>
)

const Footer = () => {
  return (
    <React.Fragment>
      <ContactForm />
      <Container as={'footer'} pt={190} pos={'relative'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Logo w={'100%'} mb={'xl'} />
          <FooterLinks />
          <NormanMumbling
            display={{ base: 'none', md: 'block' }}
            w={195}
            pos={'absolute'}
            bottom={'1rem'}
            right={{
              base: 'lg',
              xl: '3xl',
              '2xl': '20rem'
            }}
            borderRight={'1rem solid white'}
            bg={'white'}
            mr={'3rem'}
          />
          <NormanMumbling
            display={{ base: 'block', md: 'none' }}
            w={195}
            mx={'auto'}
            mt={'lg'}
            mb={'md'}
          />
          <Text
            fontSize={'xs'}
            py={'xl'}
            textAlign={{ base: 'center', md: 'left' }}
          >
            Special thanks to <Owen />
          </Text>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default Footer
