import { Global } from '@emotion/react'
import React from 'react'

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Manrope';
      src: url('../../fonts/Manrope-VariableFont_wght.woff2') format('woff2 supports variations'),
          url('../../fonts/Manrope-VariableFont_wght.woff2') format('woff2-variations');
      font-weight: 125 950;
      font-stretch: 75% 125%;
      font-style: oblique 0deg 20deg;
      font-display: swap;
    }`}
  />
)

export default Fonts
