import { graphql, useStaticQuery } from 'gatsby'
import { useContext } from 'react'
import { PageContext } from '../context'

const useSettings = () => {
  const pageContext = useContext(PageContext)
  const { allSanitySiteSettings } = useStaticQuery(
    graphql`
      query {
        allSanitySiteSettings {
          nodes {
            i18n_lang
            email
            phone
            maps
            social {
              platform
              url
            }
            contact_svg
            contact_text
            contact_cta
          }
        }
      }
    `
  )
  const settings = allSanitySiteSettings.nodes.find(
    node => node.i18n_lang === pageContext.i18n_lang
  )
  if (!settings) {
    console.warn('No settings language found!', pageContext.i18n_lang)
  }
  return settings
}

export default useSettings
