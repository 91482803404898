// eslint-disable-next-line import/no-anonymous-default-export
export default {
  xxs: '.25rem',
  xs: '.5rem',
  sm: '.75rem',
  md: '1rem',
  // py: '1.625rem', // this is the py on buttons at size 'lg'
  lg: '1.875rem', // 30px
  xl: '3.125rem', // 50px
  '2xl': '5rem', // 80px
  '3xl': '7.5rem', // 120px
  190: '12rem', // 192px
  300: '18.75rem',
  '10vw': '10vw',
  '20vw': '20vw',
  'button-y': '1.125rem'
}
