import {
  Box,
  Button,
  Collapse,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import {useSettings} from '../../gatsby-source-sanity'
import {RadioButtons} from '../UI/molecules'
import {useForm} from '@formspree/react'
import {ArrowDownIcon} from '@chakra-ui/icons'
import {IllustrationLoader} from '../illustrations'

const topicOptions = [
  {
    label: 'Website',
    value: 'website'
  },
  {
    label: 'Branding',
    value: 'branding'
  },
  {
    label: 'Marketing',
    value: 'marketing'
  },
  {
    label: 'SEO / SEM',
    value: 'seo'
  },
  {
    label: 'Copywriting',
    value: 'copywriting'
  }
]

const ContactForm = () => {
  const {contact_svg, contact_text} = useSettings()
  const [state, handleSubmit] = useForm('xdovajvz')
  return <Box
    pt={'lg'}
    pb={'3xl'}
    id={'contact'}
    bg={'gray.200'}
  >
    <Container
      as={Flex}
      justifyContent={'center'}
      flexDirection={'column'}
    >
      <Flex
        alignItems={'center'}
        flexDirection={'column'}
        textAlign={'center'}
        py={'2xl'}
      >
        {contact_svg && (
          <IllustrationLoader svg={contact_svg} maxW={350} mb={'2xl'}/>
        )}
        <Box textStyle={'big-text'} maxW={'29ch'}>
          {!state.succeeded && <span>{contact_text}</span>}
          {state.succeeded && (
            <span>
              Thanks for reaching out, we’ll be in touch with you shortly
            </span>
          )}
        </Box>
      </Flex>
      {!state.succeeded && <Form state={state} handleSubmit={handleSubmit}/>}
    </Container>
  </Box>
}

const Form = ({
                state, handleSubmit = () => {
  }
              }) => {
  const {contact_cta} = useSettings()
  const {isOpen, onToggle} = useDisclosure()
  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid gap={'lg'}>
        <FormControl isRequired textAlign={'center'}>
          <RadioButtons
            name={'interest'}
            label={'Topic'}
            disabled={state.submitting}
            options={topicOptions}
          />
        </FormControl>
        <SimpleGrid columns={{lg: 2}} gap={'lg'}>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              name={'name'}
              placeholder={'Name'}
              disabled={state.submitting}
              size={'lg'}
              bg={'white'}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              name={'email'}
              type={'email'}
              placeholder={'Your email address'}
              disabled={state.submitting}
              size={'lg'}
              bg={'white'}
            />
          </FormControl>
        </SimpleGrid>
        <Collapse in={isOpen} animateOpacity>
          <FormControl>
            <FormLabel textStyle={'text-large'}>Additional notes</FormLabel>
            <Textarea name={'message'} disabled={state.submitting}/>
          </FormControl>
        </Collapse>
        <Flex
          alignItems={{base: 'flex-start', md: 'center'}}
          justifyContent={{base: 'center', lg: 'space-between'}}
          flexDirection={{base: 'column', lg: 'row'}}
        >
          {!isOpen && (
            <Button
              variant={'link'}
              fontWeight={'500'}
              onClick={onToggle}
              rightIcon={<ArrowDownIcon/>}
              disabled={state.submitting}
              mb={'2rem'}
              color={'black'}
              ml={'xl'}
            >
              Additional notes
            </Button>
          )}
          {isOpen && <Box flex={1}/>}
          <Button
            type={'submit'}
            colorScheme={'brand'}
            textStyle={'uppercase'}
            size={'lg'}
            width={{base: '100%', md: 'auto'}}
            px={{base: 'xl', sm: '2xl'}}
            isLoading={state.submitting}
            loadingText={'Processing'}
            spinnerPlacement={'end'}
          >
            {contact_cta}
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  )
}

export default ContactForm
