import React from 'react'
import {
  ApisSVG,
  ContactSVG,
  DesignSVG,
  DevelopmentSVG,
  MagentoSVG,
  SeoSVG,
  WordpressSVG
} from './index'
import {
  ApiIcon,
  B2BIcon,
  ChatIcon,
  DesignIcon,
  DevelopmentIcon,
  ErpIcon,
  MagentoIcon,
  SeoIcon,
  SettingsIcon,
  WordpressIcon
} from './icons'
import { NormanMumbling } from './logo'

const mapping = {
  wordpress: WordpressSVG,
  magento: MagentoSVG,
  seo: SeoSVG,
  development: DevelopmentSVG,
  design: DesignSVG,
  contact: ContactSVG,
  apis: ApisSVG,
  api_icon: ApiIcon,
  b2b_icon: B2BIcon,
  chat_icon: ChatIcon,
  design_icon: DesignIcon,
  development_icon: DevelopmentIcon,
  erp_icon: ErpIcon,
  magento_icon: MagentoIcon,
  seo_icon: SeoIcon,
  settings_icon: SettingsIcon,
  wordpress_icon: WordpressIcon,
  norman_mumbling: NormanMumbling
}

const IllustrationLoader = ({ svg, ...props }) => {
  if (mapping && mapping[svg]) {
    const Illustration = mapping[svg]
    return <Illustration w={'full'} {...props} />
  }
}

export default IllustrationLoader
