import React from 'react'
import { Box } from '@chakra-ui/react'

const WordpressSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 728 701"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M556.198 177.068C506.738 198.038 449.648 174.948 428.678 125.488C409.468 80.1783 427.238 28.4683 468.378 3.98828C448.168 1.34828 427.008 3.88828 406.888 12.4183C346.628 37.9683 318.488 107.528 344.048 167.788C369.598 228.048 439.158 256.178 499.418 230.628C525.848 219.418 546.088 199.748 558.338 176.118C557.628 176.438 556.908 176.758 556.188 177.058L556.198 177.068Z"
      fill="#EAEAEA"
      stroke="#383838"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M453.852 511.309L103.191 330.159L316.611 91.3086L658.662 587.829L640.682 606.729C639.572 604.029 517.622 543.279 517.622 543.279"
      fill="white"
    />
    <path
      d="M453.852 511.309L103.191 330.159L316.611 91.3086L658.662 587.829L640.682 606.729C639.572 604.029 517.622 543.279 517.622 543.279"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M171.37 344.548C264.309 344.548 339.65 269.207 339.65 176.268C339.65 83.3298 264.309 7.98828 171.37 7.98828C78.4316 7.98828 3.08984 83.3298 3.08984 176.268C3.08984 269.207 78.4316 344.548 171.37 344.548Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M103.191 415.929C109.631 415.929 114.852 410.709 114.852 404.269C114.852 397.83 109.631 392.609 103.191 392.609C96.7518 392.609 91.5312 397.83 91.5312 404.269C91.5312 410.709 96.7518 415.929 103.191 415.929Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M371.639 361.149C377.255 361.149 381.809 356.595 381.809 350.979C381.809 345.362 377.255 340.809 371.639 340.809C366.022 340.809 361.469 345.362 361.469 350.979C361.469 356.595 366.022 361.149 371.639 361.149Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M633.67 481.11C639.287 481.11 643.84 476.556 643.84 470.94C643.84 465.323 639.287 460.77 633.67 460.77C628.053 460.77 623.5 465.323 623.5 470.94C623.5 476.556 628.053 481.11 633.67 481.11Z"
      fill="#EAEAEA"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M358.46 73.0267C370.649 73.0267 380.53 63.1456 380.53 50.9567C380.53 38.7678 370.649 28.8867 358.46 28.8867C346.272 28.8867 336.391 38.7678 336.391 50.9567C336.391 63.1456 346.272 73.0267 358.46 73.0267Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M485.708 133.769C489.795 133.769 493.108 130.456 493.108 126.369C493.108 122.282 489.795 118.969 485.708 118.969C481.622 118.969 478.309 122.282 478.309 126.369C478.309 130.456 481.622 133.769 485.708 133.769Z"
      fill="#EAEAEA"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M393.241 649.567C397.112 649.567 400.25 646.428 400.25 642.557C400.25 638.685 397.112 635.547 393.241 635.547C389.369 635.547 386.23 638.685 386.23 642.557C386.23 646.428 389.369 649.567 393.241 649.567Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M402.649 243.518C403.289 229.318 414.999 218.008 429.359 218.008C439.669 218.008 448.609 223.848 453.069 232.388C455.929 230.628 459.289 229.608 462.899 229.608C471.419 229.608 478.619 235.298 480.899 243.078H482.819C494.369 243.078 503.729 252.438 503.729 263.988C503.729 275.538 494.369 284.898 482.819 284.898H406.93C395.38 284.898 386.02 275.538 386.02 263.988C386.02 253.908 393.159 245.488 402.649 243.518Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M196.648 380.855C192.298 389.925 187.008 402.025 183.768 411.645C166.378 463.255 154.538 515.985 175.478 568.615C208.458 651.515 303.538 694.906 388.618 693.766C438.378 693.106 500.718 679.825 523.748 630.005C535.808 603.905 532.758 572.646 520.018 547.826C505.608 519.746 485.768 492.035 493.608 458.765C497.698 441.425 507.048 428.585 514.218 420.985L501.328 404.245C491.178 409.035 477.928 417.185 467.848 431.285C446.898 460.595 454.528 495.915 451.438 529.345C446.878 578.725 388.178 592.696 354.858 560.456C326.358 532.886 332.008 489.115 362.498 466.735"
      fill="#EAEAEA"
    />
    <path
      d="M196.648 380.855C192.298 389.925 187.008 402.025 183.768 411.645C166.378 463.255 154.538 515.985 175.478 568.615C208.458 651.515 303.538 694.906 388.618 693.766C438.378 693.106 500.718 679.825 523.748 630.005C535.808 603.905 532.758 572.646 520.018 547.826C505.608 519.746 485.768 492.035 493.608 458.765C497.698 441.425 507.048 428.585 514.218 420.985L501.328 404.245C491.178 409.035 477.928 417.185 467.848 431.285C446.898 460.595 454.528 495.915 451.438 529.345C446.878 578.725 388.178 592.696 354.858 560.456C326.358 532.886 332.008 489.115 362.498 466.735"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M572.278 409.297L566.218 472.947C565.648 478.907 558.678 481.847 554.018 478.087L530.488 459.127"
      fill="white"
    />
    <path
      d="M572.278 409.297L566.218 472.947C565.648 478.907 558.678 481.847 554.018 478.087L530.488 459.127"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M493.059 345.449L432.148 364.889C426.448 366.709 425.059 374.139 429.719 377.899L453.249 396.859"
      fill="white"
    />
    <path
      d="M493.059 345.449L432.148 364.889C426.448 366.709 425.059 374.139 429.719 377.899L453.249 396.859"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M480.617 443.659L452.138 420.699C445.608 415.439 444.587 405.889 449.847 399.359L502.117 334.509C502.117 334.509 533.347 290.199 587.607 298.959C591.997 299.669 595.607 302.819 597.017 307.039C601.257 319.669 606.587 343.479 597.347 369.409C592.837 382.049 586.197 391.479 580.707 397.849L528.437 462.699C523.177 469.229 513.627 470.249 507.097 464.989L480.627 443.649L480.617 443.659Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M466.57 378.607L502.121 334.497C502.121 334.497 533.35 290.187 587.61 298.947C592 299.657 595.611 302.807 597.021 307.027C601.261 319.657 606.591 343.467 597.351 369.397C592.841 382.037 586.2 391.467 580.71 397.837L545.161 441.947"
      fill="white"
    />
    <path
      d="M466.57 378.607L502.121 334.497C502.121 334.497 533.35 290.187 587.61 298.947C592 299.657 595.611 302.807 597.021 307.027C601.261 319.657 606.591 343.467 597.351 369.397C592.841 382.037 586.2 391.467 580.71 397.837L545.161 441.947"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M554.23 373.136C566.607 373.136 576.641 363.103 576.641 350.726C576.641 338.35 566.607 328.316 554.23 328.316C541.854 328.316 531.82 338.35 531.82 350.726C531.82 363.103 541.854 373.136 554.23 373.136Z"
      fill="#EAEAEA"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M545.517 441.857L467.457 378.188L466.557 378.617L449.836 399.367C444.576 405.897 445.607 415.447 452.127 420.707L480.607 443.668L507.077 465.008C513.607 470.268 523.157 469.237 528.417 462.717L545.137 441.967"
      fill="#7A6CFF"
    />
    <path
      d="M545.517 441.857L467.457 378.188L466.557 378.617L449.836 399.367C444.576 405.897 445.607 415.447 452.127 420.707L480.607 443.668L507.077 465.008C513.607 470.268 523.157 469.237 528.417 462.717L545.137 441.967"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M553.148 474.338C551.738 472.598 545.288 463.848 546.148 452.978C546.768 445.228 550.518 439.118 552.188 436.988C545.138 443.838 538.058 451.438 531.008 458.278C538.198 463.258 545.958 469.348 553.148 474.328V474.338Z"
      fill="#383838"
    />
    <path
      d="M431.629 376.886C433.629 377.896 445.159 382.886 455.599 379.736C463.049 377.486 470.359 370.076 472.079 367.996C466.889 376.336 458.819 387.316 453.629 395.666C447.229 389.696 438.029 382.856 431.629 376.886Z"
      fill="#383838"
    />
    <path
      d="M123.712 521.267C119.022 547.587 136.552 572.727 162.872 577.417C189.192 582.107 214.332 564.577 219.022 538.257C223.712 511.937 206.182 486.797 179.862 482.107C153.542 477.417 128.402 494.947 123.712 521.267Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M122.98 528.246C104.77 542.676 95.0203 557.016 99.3403 565.686C103.66 574.346 120.98 575.206 143.46 569.326C155.68 566.146 169.43 560.966 183.44 553.986C197.44 546.996 209.85 539.136 219.75 531.296C237.96 516.866 247.711 502.516 243.391 493.846C239.071 485.176 221.75 484.326 199.27 490.196"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M692.798 576.459C691.578 549.369 669.239 527.789 641.849 527.789C622.189 527.789 605.129 538.919 596.619 555.229C591.169 551.869 584.749 549.929 577.869 549.929C561.609 549.929 547.888 560.779 543.528 575.629H539.858C517.828 575.629 499.969 593.489 499.969 615.519C499.969 637.549 517.828 655.409 539.858 655.409H684.639C706.669 655.409 724.528 637.549 724.528 615.519C724.528 596.289 710.918 580.239 692.798 576.469V576.459Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M300.71 655.077H299.34C297.62 641.857 286.32 631.637 272.63 631.637C258.94 631.637 247.64 641.857 245.92 655.077H235.82C224.18 655.077 214.75 664.507 214.75 676.147C214.75 687.787 224.18 697.217 235.82 697.217H300.71C312.35 697.217 321.78 687.787 321.78 676.147C321.78 664.507 312.35 655.077 300.71 655.077Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M171.392 278.928L134.592 257.678C133.832 257.238 133.362 256.428 133.362 255.548V152.638C133.362 151.758 133.832 150.948 134.592 150.508L155.852 138.228C157.492 137.288 159.532 138.468 159.532 140.358V240.478C159.532 241.338 159.982 242.128 160.712 242.578L171.342 249.038C172.122 249.518 173.112 249.518 173.892 249.038L184.522 242.578C185.252 242.128 185.702 241.338 185.702 240.478V140.358C185.702 138.468 187.752 137.288 189.382 138.228L210.652 150.508C211.412 150.948 211.882 151.758 211.882 152.638V255.548C211.882 256.428 211.412 257.238 210.652 257.678L173.842 278.928C173.082 279.368 172.152 279.368 171.392 278.928ZM263.372 122.438V225.348C263.372 226.228 262.902 227.038 262.142 227.478L240.892 239.748C239.252 240.688 237.212 239.508 237.212 237.618V137.528C237.212 136.648 236.742 135.838 235.982 135.398L173.032 99.0678C172.272 98.6278 171.342 98.6278 170.582 99.0678L107.602 135.398C106.842 135.838 106.372 136.648 106.372 137.528V237.608C106.372 239.498 104.322 240.678 102.692 239.728L81.4722 227.458C80.7122 227.018 80.2422 226.208 80.2422 225.338V122.428C80.2422 121.548 80.7122 120.738 81.4722 120.298L170.582 68.8378C171.342 68.3978 172.282 68.3978 173.032 68.8378L262.142 120.298C262.902 120.738 263.372 121.548 263.372 122.428V122.438Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M37.7583 300.549C49.5164 300.549 59.0483 291.017 59.0483 279.259C59.0483 267.501 49.5164 257.969 37.7583 257.969C26.0002 257.969 16.4688 267.501 16.4688 279.259C16.4688 291.017 26.0002 300.549 37.7583 300.549Z"
      fill="#EAEAEA"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M541.558 48.8477H515.838C509.338 48.8477 503.918 54.0477 503.088 60.7377H499.618C492.548 60.7377 486.758 66.8877 486.758 74.4077C486.758 81.9277 492.548 88.0777 499.618 88.0777H525.338C531.838 88.0777 537.258 82.8777 538.088 76.1877H541.558C548.628 76.1877 554.418 70.0377 554.418 62.5177C554.418 54.9977 548.628 48.8477 541.558 48.8477Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M263.84 400.848H293.23C300.66 400.848 306.85 406.798 307.8 414.438H311.76C319.84 414.438 326.46 421.468 326.46 430.068C326.46 438.658 319.85 445.698 311.76 445.698H282.371C274.941 445.698 268.75 439.748 267.8 432.108H263.84C255.76 432.108 249.141 425.078 249.141 416.478C249.141 407.888 255.75 400.848 263.84 400.848Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M404.441 591.5C414.609 591.5 422.852 583.257 422.852 573.09C422.852 562.922 414.609 554.68 404.441 554.68C394.274 554.68 386.031 562.922 386.031 573.09C386.031 583.257 394.274 591.5 404.441 591.5Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M179.088 431.69C191.088 409.04 190.338 403.84 206.238 399.29C216.268 396.42 230.438 398.62 239.898 402.47C240.598 402.76 241.298 403.05 241.998 403.34C244.848 404.54 247.648 405.84 250.408 407.22C233.448 398.65 216.488 390.07 199.538 381.5C199.118 381.29 197.528 378.89 197.108 378.68C198.428 379.35 184.748 409.25 183.758 411.66C182.578 414.56 180.728 428.99 179.078 431.69H179.088Z"
      fill="#383838"
    />
    <path
      d="M419.799 490.997C419.799 490.997 444.399 500.817 451.179 471.688L450.989 507.867L419.789 490.997H419.799Z"
      fill="#383838"
    />
    <path
      d="M572.172 547.928C572.172 547.928 618.492 538.028 581.482 479.758L617.472 531.868L607.422 539.438L598.352 550.238L596.182 552.938L584.862 549.788L573.392 548.968"
      fill="#383838"
    />
    <path
      d="M341.51 184.367C341.51 184.367 350.14 143.877 380.92 187.667C411.7 231.457 328.91 111.027 328.91 111.027L333.26 127.377L341.51 184.367Z"
      fill="#383838"
    />
    <path
      d="M340.078 94.8867C340.078 94.8867 344.068 81.0367 363.268 75.0367C381.888 69.2167 352.768 73.9867 352.768 73.9867L345.348 70.3867L340.088 94.8867H340.078Z"
      fill="#383838"
    />
  </Box>
)

export default WordpressSVG
