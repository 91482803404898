// eslint-disable-next-line import/no-anonymous-default-export
export default {
  variants: {
    button: {
      color: 'white',
      bg: 'brand.500',
      borderRadius: 'md',
      py: 'xs',
      px: 'xl',
      fontWeight: 'bold',
      _hover: {
        bg: 'brand.600',
        textDecoration: 'none'
      },
      _focus: {
        bg: 'brand.600',
        textDecoration: 'none'
      }
    }
  }
}
