import React from 'react'
import { Box } from '@chakra-ui/react'

const DesignSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 953 553"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M603.26 102.914H864.89C874.17 102.914 881.7 110.444 881.7 119.724V157.574H588.66V117.524C588.66 109.464 595.2 102.924 603.26 102.924V102.914Z"
      fill="#D8D8D8"
    />
    <path
      d="M701.492 347.143H863.602C873.602 347.143 881.702 339.043 881.702 329.043V121.003C881.702 111.003 873.602 102.903 863.602 102.903L607.382 101.793C597.042 101.793 588.652 110.173 588.652 120.523V211.023"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M846.323 135.122C849.377 135.122 851.853 132.687 851.853 129.682C851.853 126.678 849.377 124.242 846.323 124.242C843.269 124.242 840.793 126.678 840.793 129.682C840.793 132.687 843.269 135.122 846.323 135.122Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M819.803 135.122C822.858 135.122 825.333 132.687 825.333 129.682C825.333 126.678 822.858 124.242 819.803 124.242C816.749 124.242 814.273 126.678 814.273 129.682C814.273 132.687 816.749 135.122 819.803 135.122Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M793.28 135.122C796.334 135.122 798.81 132.687 798.81 129.682C798.81 126.678 796.334 124.242 793.28 124.242C790.226 124.242 787.75 126.678 787.75 129.682C787.75 132.687 790.226 135.122 793.28 135.122Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M587.672 157.574H881.702"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M845.91 203.583C847.97 206.993 848.55 212.283 846.52 215.543C846.06 216.283 844.53 219.103 841.1 220.423C837.57 221.783 834.23 221.043 829.4 220.423C827 220.123 822.18 220.063 818.24 221.533C814.74 222.833 811.55 219.593 812.44 215.973C814.71 206.723 820.99 199.133 828.25 197.313C835.09 195.603 840.3 198.473 840.3 198.473C841.31 198.993 844.01 200.443 845.91 203.593V203.583Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M761.14 203.583C759.08 206.993 758.5 212.283 760.53 215.543C760.99 216.283 762.52 219.103 765.95 220.423C769.48 221.783 772.82 221.043 777.65 220.423C780.05 220.123 784.87 220.063 788.81 221.533C792.31 222.833 795.5 219.593 794.61 215.973C792.34 206.723 786.06 199.133 778.8 197.313C771.96 195.603 766.75 198.473 766.75 198.473C765.74 198.993 763.04 200.443 761.14 203.593V203.583Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M803.051 237.942H804.111L817.551 237.922L820.521 237.972C821.471 237.972 822.361 238.442 822.901 239.232C824.451 241.502 826.001 243.782 827.551 246.052C827.871 246.512 828.561 246.502 828.851 246.022L832.951 239.392C833.231 239.092 834.001 238.152 835.241 238.152C835.751 238.152 836.001 238.132 836.441 238.132H839.411L845.241 238.152C846.251 238.152 847.101 238.972 847.141 239.982C847.251 242.232 847.081 245.092 846.311 248.142C842.341 263.872 828.341 275.652 803.391 275.652"
      fill="#D8D8D8"
    />
    <path
      d="M803.051 237.942H804.111L817.551 237.922L820.521 237.972C821.471 237.972 822.361 238.442 822.901 239.232C824.451 241.502 826.001 243.782 827.551 246.052C827.871 246.512 828.561 246.502 828.851 246.022L832.951 239.392C833.231 239.092 834.001 238.152 835.241 238.152C835.751 238.152 836.001 238.132 836.441 238.132H839.411L845.241 238.152C846.251 238.152 847.101 238.972 847.141 239.982C847.251 242.232 847.081 245.092 846.311 248.142C842.341 263.872 828.341 275.652 803.391 275.652"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M804.12 237.942H803.06L789.62 237.922L786.65 237.972C785.7 237.972 784.81 238.442 784.27 239.232C782.72 241.502 781.17 243.782 779.62 246.052C779.3 246.512 778.61 246.502 778.32 246.022L774.22 239.392C773.94 239.092 773.17 238.152 771.93 238.152C771.42 238.152 771.17 238.132 770.73 238.132H767.76L761.93 238.152C760.92 238.152 760.07 238.972 760.03 239.982C759.92 242.232 760.09 245.092 760.86 248.142C764.83 263.872 778.83 275.652 803.78 275.652"
      fill="#D8D8D8"
    />
    <path
      d="M804.12 237.942H803.06L789.62 237.922L786.65 237.972C785.7 237.972 784.81 238.442 784.27 239.232C782.72 241.502 781.17 243.782 779.62 246.052C779.3 246.512 778.61 246.502 778.32 246.022L774.22 239.392C773.94 239.092 773.17 238.152 771.93 238.152C771.42 238.152 771.17 238.132 770.73 238.132H767.76L761.93 238.152C760.92 238.152 760.07 238.972 760.03 239.982C759.92 242.232 760.09 245.092 760.86 248.142C764.83 263.872 778.83 275.652 803.78 275.652"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M0.871094 549.242H386.501"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M952.65 549.242H210.68"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M165.91 447.283L124.32 517.263C124.04 517.723 123.64 518.102 123.16 518.352L64.9996 548.802C64.5596 549.032 64.0297 548.712 64.0297 548.222L63.6797 482.573C63.6797 482.033 63.8196 481.502 64.0996 481.042L105.69 411.062L165.9 447.292L165.91 447.283Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M431.573 360.731L323.003 288.561C313.873 282.521 311.173 270.331 316.903 261.001L348.823 209.031C357.963 194.151 353.313 174.681 338.433 165.531"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M207.243 310.951L153.623 278.421L148.993 275.611C129.983 264.081 123.923 239.311 135.453 220.301C146.983 201.291 171.753 195.231 190.763 206.761L218.563 223.631"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M131.97 242.832C123.75 245.882 116.45 251.632 111.55 259.702C106.7 267.702 104.96 276.722 105.98 285.342C107.41 297.212 114.08 308.332 125.09 315.012L129.72 317.822L183.34 350.352"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M107.479 281.941C99.3693 285.011 92.1692 290.731 87.3192 298.721C82.4692 306.721 80.7293 315.741 81.7493 324.361C83.1793 336.231 89.8493 347.351 100.859 354.031L105.489 356.841L159.109 389.371"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M189.631 461.47L81.6813 395.98L77.0513 393.17C58.0413 381.64 51.9812 356.87 63.5112 337.86C68.3612 329.86 74.2812 324.66 82.4012 321.59"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M105.942 357.02L219.552 425.94C230.292 432.46 233.752 446.58 227.232 457.32C220.712 468.06 206.592 471.52 195.852 465L82.2422 396.08"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M129.68 317.891L243.29 386.811C254.03 393.331 257.49 407.451 250.97 418.191C244.45 428.931 230.33 432.391 219.59 425.871"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M207.242 310.953L267.232 347.343C277.972 353.863 281.432 367.983 274.912 378.723C268.392 389.463 254.272 392.923 243.532 386.403L129.922 317.483"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M177.32 239.352L290.93 308.272C301.67 314.792 305.13 328.912 298.61 339.652C292.09 350.392 277.97 353.852 267.23 347.332"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M226.141 457.621C226.141 457.621 332.781 523.491 375.841 549.241"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M404.312 36.082L363.002 10.702C357.672 7.42202 350.682 9.12203 347.452 14.482L202.652 254.042L263.182 291.222L408.062 51.522C411.272 46.222 409.592 39.322 404.312 36.082Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M141.053 168.243H238.373L265.753 195.213C268.313 197.743 272.653 195.923 272.653 192.323V121.233C272.653 95.3727 251.503 74.2227 225.643 74.2227H141.053C115.193 74.2227 94.043 95.3727 94.043 121.233C94.043 147.093 115.193 168.243 141.053 168.243Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M149.523 127.793C153.666 127.793 157.023 124.435 157.023 120.293C157.023 116.151 153.666 112.793 149.523 112.793C145.381 112.793 142.023 116.151 142.023 120.293C142.023 124.435 145.381 127.793 149.523 127.793Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M183.852 127.793C187.994 127.793 191.352 124.435 191.352 120.293C191.352 116.151 187.994 112.793 183.852 112.793C179.709 112.793 176.352 116.151 176.352 120.293C176.352 124.435 179.709 127.793 183.852 127.793Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M218.184 127.793C222.326 127.793 225.684 124.435 225.684 120.293C225.684 116.151 222.326 112.793 218.184 112.793C214.041 112.793 210.684 116.151 210.684 120.293C210.684 124.435 214.041 127.793 218.184 127.793Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M64.1094 481.031L124.319 517.261"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M385.459 83.876L330.277 51.1632C323.934 47.4034 315.745 49.4969 311.985 55.8392L304.499 68.4671C300.739 74.8094 302.833 82.9988 309.175 86.7586L364.358 119.471C370.7 123.231 378.889 121.138 382.649 114.795L390.135 102.168C393.895 95.8252 391.801 87.6358 385.459 83.876Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M393.897 69.6377L338.714 36.9249C332.372 33.1651 324.183 35.2587 320.423 41.601L312.937 54.2288C309.177 60.5711 311.27 68.7605 317.613 72.5203L372.795 105.233C379.138 108.993 387.327 106.899 391.087 100.557L398.573 87.9292C402.332 81.5869 400.239 73.3975 393.897 69.6377Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M403.018 54.2627L347.835 21.5499C341.493 17.7901 333.304 19.8837 329.544 26.226L322.058 38.8538C318.298 45.1962 320.392 53.3855 326.734 57.1453L381.916 89.8582C388.259 93.618 396.448 91.5244 400.208 85.1821L407.694 72.5542C411.454 66.2119 409.36 58.0225 403.018 54.2627Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M411.643 39.7276L356.46 7.01475C350.118 3.25494 341.929 5.34849 338.169 11.6908L330.683 24.3187C326.923 30.661 329.017 38.8504 335.359 42.6102L390.541 75.323C396.884 79.0828 405.073 76.9893 408.833 70.6469L416.319 58.0191C420.079 51.6768 417.985 43.4874 411.643 39.7276Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M67.0625 486.711L117.872 517.261L67.0625 544.241V486.711Z"
      fill="white"
    />
    <path
      d="M217.46 222.551L216.14 223.181C208.72 235.361 197.55 247.091 179 236.761C177.99 238.371 180.15 241.981 179 243.401L199.93 255.931L222.43 219.881C221.53 219.161 218.31 223.341 217.47 222.551H217.46Z"
      fill="#383838"
    />
    <path
      d="M82.5916 456.47C82.5916 456.47 99.5616 418.35 160.272 454.08L164.152 445.47L105.022 412.66L78.1016 457.95L82.5815 456.46L82.5916 456.47Z"
      fill="#383838"
    />
    <path
      d="M66.8418 512.961C66.8418 512.961 69.3219 540.371 95.5519 529.281L64.9219 546.571L66.8418 512.961Z"
      fill="#383838"
    />
    <path
      d="M527.232 175.453C539.377 175.453 549.222 165.607 549.222 153.463C549.222 141.318 539.377 131.473 527.232 131.473C515.087 131.473 505.242 141.318 505.242 153.463C505.242 165.607 515.087 175.453 527.232 175.453Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M501.151 76.1427C506.1 76.1427 510.111 72.1311 510.111 67.1826C510.111 62.2342 506.1 58.2227 501.151 58.2227C496.203 58.2227 492.191 62.2342 492.191 67.1826C492.191 72.1311 496.203 76.1427 501.151 76.1427Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M451.113 126.413C458.646 126.413 464.753 120.306 464.753 112.773C464.753 105.24 458.646 99.1328 451.113 99.1328C443.58 99.1328 437.473 105.24 437.473 112.773C437.473 120.306 443.58 126.413 451.113 126.413Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M676.3 211.031H456.75"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M678.382 480.891H458.832"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M431.57 453.471V236.211"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M701.492 453.471V236.211"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M454.481 185.84H408.641C407.387 185.84 406.371 186.856 406.371 188.11V233.94C406.371 235.194 407.387 236.21 408.641 236.21H454.481C455.735 236.21 456.751 235.194 456.751 233.94V188.11C456.751 186.856 455.735 185.84 454.481 185.84Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M724.411 185.84H678.571C677.317 185.84 676.301 186.856 676.301 188.11V233.94C676.301 235.194 677.317 236.21 678.571 236.21H724.411C725.664 236.21 726.681 235.194 726.681 233.94V188.11C726.681 186.856 725.664 185.84 724.411 185.84Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M454.481 453.469H408.641C407.387 453.469 406.371 454.485 406.371 455.739V501.569C406.371 502.822 407.387 503.839 408.641 503.839H454.481C455.735 503.839 456.751 502.822 456.751 501.569V455.739C456.751 454.485 455.735 453.469 454.481 453.469Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M724.411 453.469H678.571C677.317 453.469 676.301 454.485 676.301 455.739V501.569C676.301 502.822 677.317 503.839 678.571 503.839H724.411C725.664 503.839 726.681 502.822 726.681 501.569V455.739C726.681 454.485 725.664 453.469 724.411 453.469Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M466.643 344.06L515.704 295.017C543.986 266.744 589.891 266.752 618.164 295.035L667.207 344.095C667.688 344.576 667.688 345.354 667.207 345.835L618.146 394.878C589.864 423.15 543.959 423.142 515.686 394.86L466.643 345.8C466.162 345.319 466.162 344.541 466.643 344.06Z"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M567.19 385.02C589.386 385.02 607.38 367.031 607.38 344.84C607.38 322.649 589.386 304.66 567.19 304.66C544.994 304.66 527 322.649 527 344.84C527 367.031 544.994 385.02 567.19 385.02Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M567.191 360.639C575.917 360.639 582.991 353.565 582.991 344.839C582.991 336.113 575.917 329.039 567.191 329.039C558.464 329.039 551.391 336.113 551.391 344.839C551.391 353.565 558.464 360.639 567.191 360.639Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M617.051 481.031L721.941 549.241"
      stroke="#383838"
      strokeWidth="6"
      strokeLinejoin="round"
    />
    <path
      d="M897.143 324.792H778.003L750.753 298.402C748.203 295.932 743.883 297.712 743.883 301.232V370.792C743.883 396.092 764.943 416.792 790.683 416.792H897.153C922.893 416.792 943.953 396.092 943.953 370.792C943.953 345.492 922.893 324.792 897.153 324.792H897.143Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M778.562 381.754H911.782"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M778.562 357.023H911.782"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M851.85 477.063C856.688 477.063 860.61 473.141 860.61 468.303C860.61 463.465 856.688 459.543 851.85 459.543C847.012 459.543 843.09 463.465 843.09 468.303C843.09 473.141 847.012 477.063 851.85 477.063Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M778.56 517.432C787.656 517.432 795.03 510.058 795.03 500.962C795.03 491.866 787.656 484.492 778.56 484.492C769.464 484.492 762.09 491.866 762.09 500.962C762.09 510.058 769.464 517.432 778.56 517.432Z"
      fill="white"
      stroke="#383838"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
  </Box>
)

export default DesignSVG
