import {
  AspectRatio,
  Badge,
  Heading,
  Flex,
  LinkBox,
  LinkOverlay,
  Text
} from '@chakra-ui/react'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { SanityImage } from '../../gatsby-source-sanity'
import { Image } from '../modules'

const ShowcaseCard = ({
  size = 'md',
  index,
  isBigClient,
  featuredImage,
  title,
  website,
  path,
  _type,
  showFooter = true
}) => {
  const imgAspect = featuredImage?.asset.width / featuredImage?.asset.height

  return (
    <AspectRatio ratio={{ base: 0.9, md: size === 'md' ? 1.44 : 2 }}>
      <LinkBox
        layerStyle={'panel'}
        _hover={{ bg: 'gray.100', '.cta': { color: 'brand.500' } }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'absolute',
            left: 'xl',
            top: 'xl',
            right: 'xl'
          }}
        >
          <Text as={'span'} textStyle={'play-down'} fontSize={'lg'}>
            {(++index + '').padStart(2, '0')}.
          </Text>
          {isBigClient && <Badge variant={'brand'} fontSize={{base:'xs', md: 'sm', lg: 'md'}}
                                 px={{base:'md', sm:'lg', md: 'xl'}}>Featured</Badge>}
        </Flex>
        {featuredImage && (
          <Image
            value={featuredImage}
            widthArg={1000}
            w={imgAspect < 1.44 && imgAspect * 230}
            mx={'auto'}
            maxW={{ base: 300, lg: size === 'md' ? 450 : 800 }}
            maxH={{ base: 230, md: size === 'md' ? 230 : 400 }}
          />
        )}
        {showFooter && (
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <LinkOverlay as={Link} to={path}>
              {title}
            </LinkOverlay>
            {isBigClient && <Badge variant={'brand'}>Featured</Badge>}
          </Flex>
        )}
        {website && (
          <LinkOverlay href={website} isExternal>
            <Flex
              className="cta"
              as={'span'}
              alignItems={'center'}
              justifyContent={'end'}
              sx={{
                position: 'absolute',
                left: 'xl',
                bottom: 'xl',
                right: 'xl'
              }}
            >
              <Heading color={'brand.600'} as={'span'} size="text">
                View project →
              </Heading>
            </Flex>
          </LinkOverlay>
        )}
      </LinkBox>
    </AspectRatio>
  )
}

export default ShowcaseCard

export const fragments = graphql`
  fragment ShowcasePostCard on SanityPost {
    title
    _type
    path
    featuredImage {
      asset {
        _id
      }
    }
  }
  fragment ShowcaseProjectCard on SanityProject {
    title
    _type
    path
    isBigClient
    website
    featuredImage: logo {
      asset {
        _id
        width
        height
      }
    }
  }
`
