import React from 'react'
import { Box } from '@chakra-ui/react'

const SVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.1 58.27H26.1C25.877 58.272 25.6629 58.3574 25.5001 58.5098L18.1301 65.7002C17.9541 65.8747 17.7302 65.9926 17.4869 66.0396C17.2436 66.0865 16.9918 66.0603 16.7635 65.9639C16.5352 65.8674 16.3407 65.7053 16.2048 65.498C16.0688 65.2908 15.9976 65.0476 16.0001 64.7998V47.1802C15.9987 45.723 16.2846 44.2798 16.8414 42.9331C17.3981 41.5865 18.2148 40.3624 19.2447 39.3315C20.2746 38.3007 21.4977 37.4832 22.8438 36.9253C24.19 36.3673 25.6328 36.0801 27.09 36.0801H42.7901"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.9016 68.4097H63.6316C63.7426 68.4075 63.8529 68.4275 63.9559 68.4688C64.059 68.51 64.1527 68.5713 64.2316 68.6494L71.6116 75.8394C71.7893 76.009 72.0128 76.1232 72.2545 76.1675C72.4962 76.2118 72.7457 76.1845 72.972 76.0889C73.1984 75.9932 73.3918 75.8336 73.5286 75.6294C73.6653 75.4252 73.7394 75.1852 73.7416 74.9395V57.3096C73.7389 54.3674 72.5683 51.5468 70.4869 49.4673C68.4056 47.3878 65.5838 46.2197 62.6416 46.2197H46.9417C44.0004 46.2197 41.1795 47.388 39.0997 49.4678C37.02 51.5476 35.8516 54.3683 35.8516 57.3096V57.3096C35.8489 60.2456 37.0106 63.0628 39.082 65.1436C41.1535 67.2243 43.9656 68.3991 46.9016 68.4097V68.4097Z"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M57.3633 39.5703C61.7816 39.5703 65.3633 35.9886 65.3633 31.5703C65.3633 27.152 61.7816 23.5703 57.3633 23.5703C52.945 23.5703 49.3633 27.152 49.3633 31.5703C49.3633 35.9886 52.945 39.5703 57.3633 39.5703Z"
      stroke="#7A6CFF"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M32.9531 30C37.3714 30 40.9531 26.4183 40.9531 22C40.9531 17.5817 37.3714 14 32.9531 14C28.5348 14 24.9531 17.5817 24.9531 22C24.9531 26.4183 28.5348 30 32.9531 30Z"
      stroke="#383838"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Box>
)

export default SVG
