export const onRouteUpdate = ({ location: { hash } }) => {}

export const shouldUpdateScroll = ({
  routerProps: {
    location: { hash }
  }
}) => {}
export const onClientEntry = () => {
  if (process.env.ENABLE_LOCAL_SW) {
    registerServiceWorker()
  }
}

export const registerServiceWorker = () => {
  if (typeof window !== 'undefined') {
    // Do magic
  }
}

// export const onServiceWorkerUpdateReady = () => {
//     const answer = window.confirm(
//         `This application has been updated. ` +
//         `Reload to display the latest version?`
//     )
//
//     if (answer === true) {
//         window.location.reload()
//     }
// }
