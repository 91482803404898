import {Flex} from '@chakra-ui/react'
import React from 'react'
import Footer from './Footer'
import Header from './Header'
import { PageContextProvider } from '../context'
import Fonts from './Fonts'

const Layout = ({ pageContext, location, children }) => (
  <PageContextProvider value={{ location, pageContext }}>
    <Fonts />
    <Header />
    <Flex direction={'column'}>
      {children}
    </Flex>
    <Footer />
  </PageContextProvider>
)

export default Layout
