import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { Box, Flex } from '@chakra-ui/react'

const ViewportSlider = ({
  offsetTop = 142,
  items,
  slideComponent,
  ...props
}) => {
  const SlideComponent = slideComponent
  const screenScrollPerSlide = 1 // change this to increase decrease slide screen time
  const toVH = value => value * screenScrollPerSlide * 100 + 'vh'
  const slideItems = items.length
  const sliderHeight = toVH(slideItems + 1)
  const slideHeight = toVH(1)
  const ref = useRef(null)
  const { scrollY } = useScroll()
  let windowHeight = 0
  if (typeof window !== 'undefined') {
    windowHeight = window.innerHeight
  }
  const pageRange = [
    offsetTop,
    (slideItems - 1) * (windowHeight * screenScrollPerSlide)
  ] // 143 is header height
  const heightMap = useTransform(scrollY, pageRange, [0, slideItems - 1])
  const position = useTransform(
    heightMap,
    value => parseInt(value) * screenScrollPerSlide * -100
  )
  const y = useTransform(position, value => value + 'vh')

  return (
    <Box ref={ref} h={sliderHeight} position={'relative'} {...props}>
      <Flex flexDirection={'column'} h={'full'}>
        <Box
          position={'sticky'}
          top={'0'}
          h={'100vh'}
          as={motion.div}
          style={{ y }}
        >
          {items.map((item, index) => (
            <Box h={slideHeight} key={index + '-slide'}>
              <SlideComponent index={index} {...item} />
            </Box>
          ))}
        </Box>
        <Flex flex={1} />
      </Flex>
      {/*<Box position={'absolute'} left={0} top={100 + 'vh'} w={'full'} h={'1px'} bg={'red.500'}/>*/}
      {/*<Box position={'absolute'} left={0} top={200 + 'vh'} w={'full'} h={'1px'} bg={'red.500'}/>*/}
      {/*<Box position={'absolute'} left={0} top={300 + 'vh'} w={'full'} h={'1px'} bg={'red.500'}/>*/}
      {/*<Box position={'absolute'} left={0} top={400 + 'vh'} w={'full'} h={'1px'} bg={'red.500'}/>*/}
      {/*<Box position={'absolute'} left={0} top={500 + 'vh'} w={'full'} h={'1px'} bg={'red.500'}/>*/}
    </Box>
  )
}

export default ViewportSlider
