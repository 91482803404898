import React from 'react'
import { Box } from '@chakra-ui/react'

const MagentoSVG = props => (
  <Box
    as={'svg'}
    {...props}
    viewBox="0 0 649 620"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M328.222 591.291C492.14 591.291 625.021 569.783 625.021 543.251C625.021 516.719 492.14 495.211 328.222 495.211C164.304 495.211 31.4219 516.719 31.4219 543.251C31.4219 569.783 164.304 591.291 328.222 591.291Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M328.22 571.172C462.37 571.172 571.121 558.672 571.121 543.252C571.121 527.832 462.37 515.332 328.22 515.332C194.07 515.332 85.3203 527.832 85.3203 543.252C85.3203 558.672 194.07 571.172 328.22 571.172Z"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M327.399 546.883C358.703 546.883 384.079 544.496 384.079 541.553C384.079 538.609 358.703 536.223 327.399 536.223C296.096 536.223 270.719 538.609 270.719 541.553C270.719 544.496 296.096 546.883 327.399 546.883Z"
      fill="#383838"
    />
    <path
      d="M623.241 546.88C623.651 546.24 625.02 546.46 625.02 547.16V567.57C625.02 594.1 492.14 615.61 328.22 615.61C164.3 615.61 31.4203 594.1 31.4203 567.57V543.25C31.4203 543.25 27.6301 588.54 328.22 591.29C328.22 591.29 593.971 592.81 623.241 546.88Z"
      fill="#383838"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M571.121 543.74V247.31C571.121 113.16 462.37 4.41016 328.22 4.41016C194.07 4.41016 85.3203 113.16 85.3203 247.31V543.74"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M607.003 498.299C613.056 498.299 617.963 493.392 617.963 487.339C617.963 481.286 613.056 476.379 607.003 476.379C600.95 476.379 596.043 481.286 596.043 487.339C596.043 493.392 600.95 498.299 607.003 498.299Z"
      fill="white"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M31.4194 231.28C39.5988 231.28 46.2295 224.649 46.2295 216.47C46.2295 208.291 39.5988 201.66 31.4194 201.66C23.2401 201.66 16.6094 208.291 16.6094 216.47C16.6094 224.649 23.2401 231.28 31.4194 231.28Z"
      fill="white"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M218.812 97.32C223.732 97.32 227.722 93.3309 227.722 88.41C227.722 83.4891 223.732 79.5 218.812 79.5C213.891 79.5 209.902 83.4891 209.902 88.41C209.902 93.3309 213.891 97.32 218.812 97.32Z"
      fill="white"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M16.6113 365.149C23.7468 365.149 29.5312 359.364 29.5312 352.229C29.5312 345.093 23.7468 339.309 16.6113 339.309C9.47581 339.309 3.69141 345.093 3.69141 352.229C3.69141 359.364 9.47581 365.149 16.6113 365.149Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M620.832 191.59C625.753 191.59 629.742 187.6 629.742 182.68C629.742 177.759 625.753 173.77 620.832 173.77C615.911 173.77 611.922 177.759 611.922 182.68C611.922 187.6 615.911 191.59 620.832 191.59Z"
      fill="white"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M575.693 294.538C553.903 301.178 541.632 324.228 548.272 346.008C554.912 367.798 577.962 380.067 599.742 373.427C621.532 366.787 633.802 343.737 627.162 321.957C620.522 300.167 597.473 287.898 575.693 294.538Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M570.112 296.689C552.112 288.459 537.452 286.629 532.552 293.269C527.662 299.899 533.712 313.369 546.882 328.149C554.032 336.179 563.282 344.609 574.022 352.529C584.752 360.439 595.522 366.809 605.302 371.279C623.312 379.499 637.972 381.339 642.872 374.699C647.772 368.059 641.712 354.599 628.542 339.819"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M327.653 378.25L300.963 362.84C300.413 362.52 300.073 361.93 300.073 361.3V286.67C300.073 286.03 300.413 285.45 300.963 285.13L316.383 276.23C317.573 275.54 319.053 276.4 319.053 277.77V350.38C319.053 351 319.373 351.58 319.913 351.9L327.623 356.58C328.193 356.92 328.903 356.92 329.473 356.58L337.183 351.9C337.713 351.58 338.043 351 338.043 350.38V277.77C338.043 276.4 339.523 275.54 340.713 276.23L356.143 285.14C356.693 285.46 357.033 286.05 357.033 286.68V361.31C357.033 361.95 356.693 362.53 356.143 362.85L329.443 378.26C328.893 378.58 328.213 378.58 327.663 378.26L327.653 378.25ZM394.353 264.76V339.39C394.353 340.03 394.013 340.61 393.463 340.93L378.053 349.83C376.863 350.52 375.383 349.66 375.383 348.29V275.71C375.383 275.07 375.043 274.49 374.493 274.17L328.843 247.83C328.293 247.51 327.613 247.51 327.062 247.83L281.383 274.17C280.833 274.49 280.493 275.08 280.493 275.71V348.29C280.493 349.66 279.013 350.52 277.823 349.83L262.433 340.93C261.883 340.61 261.543 340.02 261.543 339.39V264.76C261.543 264.12 261.883 263.54 262.433 263.22L327.062 225.9C327.613 225.58 328.293 225.58 328.843 225.9L393.473 263.22C394.023 263.54 394.363 264.13 394.363 264.76H394.353Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M522.193 413.6C550.255 364.996 485.79 275.243 378.208 213.13C270.625 151.018 160.665 140.066 132.603 188.67C104.541 237.274 169.006 327.028 276.588 389.141C384.17 451.253 494.132 462.204 522.193 413.6Z"
      stroke="#D8D8D8"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M378.216 389.14C485.798 327.027 550.262 237.273 522.201 188.669C494.139 140.065 384.178 151.016 276.596 213.129C169.014 275.241 104.549 364.995 132.611 413.599C160.672 462.203 270.634 451.252 378.216 389.14Z"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M522.193 413.6C550.255 364.996 485.79 275.243 378.208 213.13C270.625 151.018 160.665 140.066 132.603 188.67C104.541 237.274 169.006 327.028 276.588 389.141C384.17 451.253 494.132 462.204 522.193 413.6Z"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M522.193 413.6C550.255 364.996 485.79 275.243 378.208 213.13C270.625 151.018 160.665 140.066 132.603 188.67C104.541 237.274 169.006 327.028 276.588 389.141C384.17 451.253 494.132 462.204 522.193 413.6Z"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M469.233 184.528C481.489 184.528 491.423 174.594 491.423 162.338C491.423 150.083 481.489 140.148 469.233 140.148C456.978 140.148 447.043 150.083 447.043 162.338C447.043 174.594 456.978 184.528 469.233 184.528Z"
      fill="#D8D8D8"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M167.222 318.768C176.964 318.768 184.862 310.871 184.862 301.128C184.862 291.386 176.964 283.488 167.222 283.488C157.479 283.488 149.582 291.386 149.582 301.128C149.582 310.871 157.479 318.768 167.222 318.768Z"
      fill="#7A6CFF"
      stroke="#383838"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
    <path
      d="M327.401 526.059C383.525 526.059 429.021 425.355 429.021 301.129C429.021 176.904 383.525 76.1992 327.401 76.1992C271.278 76.1992 225.781 176.904 225.781 301.129C225.781 425.355 271.278 526.059 327.401 526.059Z"
      stroke="#7A6CFF"
      strokeWidth="7.11"
      strokeMiterlimit="10"
    />
  </Box>
)

export default MagentoSVG
